import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { CSVLink } from "react-csv";
import Checkbox from "@material-ui/core/Checkbox";
import { useStyles } from "./utils";
import axios from "../../common/AxiosConfig";
import InputWithoutValidator from "../../common/InputWithoutValidator";

const BubbleChartMain = (props) => {
  const [tableHeaders, setTableHeaders] = useState([]);
  const [updatedHeader, setUpdatedHeader] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const classes = useStyles();
  const [filteredPosts, setFilteredPosts] = React.useState([]);
  const [select, setSelect] = useState([]);
  const [polymerOption, setPolymerOption] = useState([]);
  const [exposureOption, setExposureOption] = useState([]);
  const [tradeOption, setTradeOption] = useState([]);
  const [sourceReportOption, setSourceReportOption] = useState([]);
  const [selected, setSelected] = useState([]);
  const [tradeNameSelected, setTradeNameSelected] = useState([]);
  const [exposureSelected, setExposureSelected] = useState([]);
  const [sourceReportSelected, setSourceReportSelected] = useState([]);
  const [concenteration, setConcenteration] = useState([]);
  const [temperature, setTemperature] = useState([]);
  const [resistance, setResistance] = useState([]);
  const [DpData, setDpData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(true);
  const [exposureFlag, setExposureFlag] = useState(true);

  let options = [];
  let exposure = [];
  let reportOption = [];
  let tradeNameOption = [];
  useEffect(() => {
    let url = "";
    setLoading(true);
    if (props.chartType === "bubble") {
      url = "https://digipol.azurewebsites.net/dp_qual_data";
    } else if (props.chartType === "highBubble") {
      url = "https://digipol.azurewebsites.net/dp_qual_ht1_data";
    } else if (props.chartType === "highDataExposure") {
      url = "https://digipol.azurewebsites.net/dp_qual_ht2_data";
    }
    const token = localStorage.getItem("accessToken");

    axios({
      method: "GET",
      url: url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      setLoading(false);
      setDpData(res?.data?.data);
      // setFilteredPosts(res?.data?.data)
    });
  }, [props.chartType]);

  useEffect(() => {
    if (
      selected?.length > 0 &&
      !tradeOption?.join("")?.length > 0 &&
      !tradeNameSelected?.length > 0
    ) {
      setExposureFlag(false);
    } else if (
      selected?.length > 0 &&
      tradeOption?.join("")?.length > 0 &&
      tradeNameSelected?.length > 0
    ) {
      setExposureFlag(false);
    } else {
      setExposureFlag(true);
    }
  }, [selected, tradeNameSelected, tradeNameOption]);

  useEffect(() => {
    //Filter options updated so apply all filters here
    let result = [...DpData];
    result =
      selected.length > 0 ? filterPolymer(result, "Polymer", selected) : result;

    result =
      tradeNameSelected.length > 0 && !tradeNameSelected.includes("No Data")
        ? filterPolymer(result, "Trade name", tradeNameSelected)
        : result;

    result =
      exposureSelected.length > 0
        ? filterPolymer(result, "Exposure medium", exposureSelected)
        : result;
    result =
      sourceReportSelected.length > 0
        ? filterPolymer(result, "Report source", sourceReportSelected)
        : result;

    setFilteredPosts(result);
  }, [
    exposureSelected,
    selected,
    sourceReportSelected,
    tradeNameSelected,
    DpData,
  ]);

  useEffect(() => {
    options = getPolymer("Polymer")?.sort((a, b) => a - b);
    tradeNameOption = getTradeNameValues("Polymer")?.sort((a, b) => a - b);
    // if(tradeNameOption[0]===""){
    //   tradeNameOption[0]="No Data"
    // }
    exposure = getExposureMedium("Exposure medium")?.sort((a, b) => a - b);
    reportOption = getSourceReportValues("Report source")?.sort(
      (a, b) => a - b
    );
    setPolymerOption(options);
    setTradeOption(tradeNameOption);
    setExposureOption(exposure);
    setSourceReportOption(reportOption);
  }, [
    DpData,
    selected,
    sourceReportSelected,
    tradeNameSelected,
    exposureSelected,
  ]);

  const getTradeNameValues = (label) => {
    var filtered = DpData?.filter(function (item) {
      return selected.indexOf(item[`${label}`]) !== -1;
    });
    let arr = filtered?.filter((item) => item["Concentration (vol%)"]);
    let permittedValues = arr?.map((value) => value[`Trade name`]);
    // const newArr = permittedValues?.map(item => typeof(item) == "undefined"? 'NO DATA' : item);
    let uniqueArray = permittedValues?.filter(function (item, pos) {
      return permittedValues?.indexOf(item) === pos;
    });
    return uniqueArray;
  };
  const getSourceReportValues = (label) => {
    let filtered = [];
    if (tradeNameSelected?.length === 0) {
      filtered = DpData?.filter(function (item) {
        return (
          selected.indexOf(item["Polymer"]) !== -1 &&
          exposureSelected &&
          exposureSelected.indexOf(item["Exposure medium"]) !== -1
        );
      });
    } else {
      filtered = DpData?.filter(function (item) {
        return (
          selected.indexOf(item["Polymer"]) !== -1 &&
          tradeNameSelected &&
          tradeNameSelected.indexOf(item["Trade name"]) !== -1 &&
          exposureSelected &&
          exposureSelected.indexOf(item["Exposure medium"]) !== -1
        );
      });
    }

    //   var filtered = DpData?.filter(function(item) {
    //     return selected.indexOf(item['Polymer']) !== -1 && tradeNameSelected&&tradeNameSelected.indexOf(item['Trade name']) !== -1  && exposureSelected&&exposureSelected.indexOf(item['Exposure medium']) !== -1   ;
    // });
    let arr = filtered?.filter((item) => item["Concentration (vol%)"]);
    let permittedValues = arr?.map((value) => value[`${label}`]);
    let uniqueArray = permittedValues?.filter(function (item, pos) {
      return permittedValues?.indexOf(item) == pos;
    });
    return uniqueArray;
  };
  const getExposureMedium = (label) => {
    let filtered = [];

    if (tradeNameSelected?.length === 0) {
      filtered = DpData?.filter(function (item) {
        return selected.indexOf(item["Polymer"]) !== -1;
      });
    } else {
      filtered = DpData?.filter(function (item) {
        return (
          selected.indexOf(item["Polymer"]) !== -1 &&
          tradeNameSelected &&
          tradeNameSelected.indexOf(item["Trade name"]) !== -1
        );
      });
    }
    let arr = filtered?.filter((item) => item["Concentration (vol%)"]);
    let permittedValues = arr?.map((value) => value[`${label}`]);

    let uniqueArray = permittedValues?.filter(function (item, pos) {
      return permittedValues?.indexOf(item) == pos;
    });
    return uniqueArray;
  };

  const getPolymer = (label) => {
    let permittedValues = DpData?.map((value) => value[`${label}`]);
    let uniqueArray = permittedValues?.filter(function (item, pos) {
      return permittedValues?.indexOf(item) == pos;
    });
    return uniqueArray;
  };

  useEffect(() => {
    const result = Object.keys(Object.assign({}, DpData[0]));
    let replacedItem = result?.map((item) =>
      item == "Temperature (C)" ? "Temperature (\u00B0C)" : item
    );
    setUpdatedHeader(replacedItem);
    setTableHeaders(result);
  }, [DpData]);

  const filterPolymer = (arr, label, temp) => {
    return arr.filter((item) => temp?.includes(item[`${label}`]));
  };

  const handleChange = (event, value) => {
    setFlag(true);
    setSelected(value);
    setTradeNameSelected([]);
    setExposureSelected([]);
    setSourceReportSelected([]);
  };

  const handleTradeName = (event, value) => {
    setFlag(true);
    setTradeNameSelected(value);
    setExposureSelected([]);
    setSourceReportSelected([]);
  };

  const handleExposure = (event, value) => {
    setFlag(true);
    setExposureSelected(value);
    setSourceReportSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function stableSort(array) {
    const stabilizedThis = array?.sort((a, b) => a["DP ID"] - b["DP ID"]);
    return stabilizedThis;
  }

  // }
  const getValues = (label) => {
    let permittedValues = filteredPosts.map((value) => value[`${label}`]);
    let uniqueArray = permittedValues.filter(function (item, pos) {
      return permittedValues.indexOf(item) == pos;
    });
    return permittedValues;
  };

  useEffect(() => {
    setConcenteration(getValues("Concentration (vol%)"));
    setTemperature(getValues("Temperature (C)"));
    setResistance(getValues("Resistance"));
  }, [selected, exposureSelected, filteredPosts]);

  const handleSubmit = () => {
    setLoading(true);
    setConcenteration(getValues("Concentration (vol%)"));
    setTemperature(getValues("Temperature (C)"));
    setResistance(getValues("Resistance"));
    setFlag(false);
    setLoading(false);
  };

  const resetHandler = () => {
    setSelected([]);
    setTradeNameSelected([]);
    setExposureSelected([]);
    setSourceReportSelected([]);
    setFlag(true);
  };

  const getColor = (data) => {
    // enter your conditional coloring code here
    switch (data) {
      case 0:
        return "silver";
      case 1:
        return "#D93028";
      case 2:
        return "#F36642";
      case 3:
        return "#FBAB62";
      case 4:
        return "#FDE58F";
      case 5:
        return "#D8EE8A";
      case 6:
        return "#A3D969";
      case 7:
        return "#60BB60";
      case 8:
        return "#18934D";
      case 9:
        return "#1A924D";
      case 10:
        return "#066D36";
    }
  };

  let tempRes = [...resistance];
  tempRes = tempRes?.map((item) => (item == 0 ? 5 : item));

  return (
    <>
      <fieldset style={{ height: "auto", display: "flex" }}>
        <legend align="left" style={{ fontWeight: "bold", color: "gray" }}>
          {" "}
          Filter{" "}
        </legend>
        {/* <Box> */}
        <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <FormControl className={classes.formControl}>
          <InputWithoutValidator
            value={selected}
            options={polymerOption} // Replace with your actual options
            onChange={handleChange}
            label="Polymer"
            multiple
            loading={loading} // Replace with your loading state
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl className={classes.formControl}>
          <InputWithoutValidator
            value={tradeNameSelected}
            options={tradeOption.filter((x) => x !== "").sort()} // Replace with your actual options
            onChange={handleTradeName}
            label="Trade Name"
            multiple
            loading={!tradeOption?.join("").length > 0} // Replace with your loading state
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl className={classes.formControl}>
          <InputWithoutValidator
            value={exposureSelected}
            options={exposureOption.filter((x) => x !== "").sort()} // Replace with your actual options
            onChange={handleExposure}
            label="Exposure"
            multiple
            loading={exposureFlag || loading} // Replace with your loading state
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl className={classes.formControl}>
          <InputWithoutValidator
            value={sourceReportSelected}
            options={sourceReportOption.filter((x) => x !== "").sort()} // Replace with your actual options
            onChange={(e, newValue) => setSourceReportSelected(newValue)}
            label="Source Report"
            multiple
            loading={loading || !sourceReportOption?.join("").length > 0} // Replace with your loading state
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '10px' }}>
          <Button
            id="btnPhCalcPredict"
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <Button
            id="btnPhCalcReset"
            style={{ marginLeft: '20px' }}
            onClick={resetHandler}
            variant="contained"
            color="primary"
          >
            Clear
          </Button>
        </div>
      </Grid>
    </Grid>
        {/* </Box> */}
      </fieldset>
      {!loading ? (
        <Box style={{ marginTop: "10px" }}>
          {!flag && (
            <Grid container>
              <Grid item xs={12} md={12}>
                {/* <Delayed> */}
                <Plot
                  var
                  data={[
                    {
                      // x: [40, 60, 80, 100],
                      // y: [40, 60, 80, 100],
                      x: concenteration,
                      y: temperature,
                      mode: "markers",
                      customdata: resistance?.map((data) => data),
                      hovertemplate:
                        "%{xaxis.title.text}: %{x}<br>" +
                        "%{yaxis.title.text}: %{y}<br>" +
                        "Resistance: %{customdata}" +
                        "<extra></extra>",
                      marker: {
                        showscale: true,

                        size: tempRes?.map((data) => data * 5),

                        cmax: 10,
                        cmin: 0,
                        colorbar: {
                          title: "Chemical Resistance",
                          titleside: "right",
                          titlefont: {
                            size: 12,
                            family: "Arial, sans-serif",
                          },
                          tick0: 0,
                          dtick: 1,
                          tickmode: "array",
                          tickvals: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                          ticktext: ["No Data", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],

                          // nticks:20,
                        },
                        // color:[0,1],
                        color: filteredPosts.map((d) =>
                          getColor(d["Resistance"])
                        ),
                        // color:getColor(resistance),
                        // color: [28.666666666666668, 20.666666666666668, 15.333333333333334,         17.666666666666668, 19.0, 17.666666666666668, 26.0, 21.0, 21.666666666666668, 27.0, 21.666666666666668, 16.666666666666668, 27.0, 14.0, 29.666666666666668, 22.0, 16.0, 28.0, 27.0, 25.333333333333332],
                        colorscale: [
                          [0.0, "silver"],
                          [0.085, "silver"],
                          [0.111, "#D93028"],
                          [0.222, "#F36642"],
                          [0.333, "#FBAB62"],
                          [0.444, "#FDE58F"],
                          [0.556, "#D8EE8A"],
                          [0.667, "#A3D969"],
                          [0.778, "#60BB60"],
                          [0.889, "#18934D"],
                          [0.989, "#066D36"],
                          [1.0, "#066D36"],
                        ],
                      },

                      type: "scattergl",
                    },
                  ]}
                  let
                  layout={{
                    title: {
                      text: `<b>Polymer</b> : ${selected}  <b>Trade Name</b> : ${tradeNameSelected}  <b>Exposure Medium</b> : ${exposureSelected}  <b>Source Report</b> : ${sourceReportSelected}`,
                      font: {
                        family: "Open Sans, verdana, arial, sans-serif",
                        size: 12,
                        fontWeight: 400,
                      },
                      xref: "paper",
                      x: 0.05,
                    },
                    hovermode: "closest",
                    xaxis: {
                      title: "Concentration (vol %)",

                      titlefont: {
                        family: "Arial, sans-serif",
                        size: 13,
                        color: "black",
                      },
                    },
                    yaxis: {
                      title: "Temperature (\u00B0C)",
                      titlefont: {
                        family: "Arial, sans-serif",
                        size: 12,
                        color: "black",
                      },
                    },
                    autosize: true, // Ensures the plot adjusts size based on container size
                    margin: {
                      l: 50,
                      r: 50,
                      b: 100,
                      t: 100,
                      pad: 20,
                    },
                    automargin: true,
                  }}
                />
                {/* </Delayed> */}
              </Grid>
              <Grid item xs={12}>
                {filteredPosts && tableHeaders ? (
                  <>
                    <div
                      id="divCSVDownload"
                      style={{ float: "right", marginTop: "20px" }}
                    >
                      <CSVLink
                        filename={"DP" + ".csv"}
                        data={filteredPosts}
                        headers={updatedHeader}
                      >
                        <Button variant="contained" color="primary">
                          Export Excel
                        </Button>
                      </CSVLink>
                    </div>
                    <br></br>
                    <br></br>
                    <div className={classes.root}>
                      <Paper className={classes.paper}>
                        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                        <TableContainer>
                          <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            aria-label="enhanced table"
                          >
                            <TableHead>
                              <TableRow>
                                {updatedHeader?.map((data, i) => (
                                  <TableCell key={i} align="right">
                                    {data}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {filteredPosts &&
                                stableSort(filteredPosts)
                                  ?.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  ?.map((row, index) => {
                                    // const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                      <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={row.name}
                                      >
                                        {tableHeaders.map((element, j) => (
                                          <TableCell align="center">
                                            {row[`${element}`]}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    );
                                  })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={filteredPosts ? filteredPosts.length : 0}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </Paper>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          )}
        </Box>
      ) : (
        <Box
          sx={{ display: "flex" }}
          style={{ display: "flex", margin: "50px", justifyContent: "center" }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default BubbleChartMain;
