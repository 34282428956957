import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// import { Route, Link, BrowserRouter as Router } from 'react-router-dom'
import Login from './authentication/Login';
import Routers from './components/Routers';

ReactDOM.render(<Routers/>, document.getElementById('root'))


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
