import React from 'react'
import { Route, Link,Switch,Redirect, BrowserRouter  } from 'react-router-dom'
import App from '../App'
import Login from '../authentication/Login'
import ProtectedRoute from '../commoncomponents/autocomplete/ProtectedRoute'
const Routers = () => {
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/home" component={App} />
          {/* <ProtectedRoute exact path="/home"  >
            <App/>
            </ProtectedRoute> */}
          <Redirect from='*' to='/home' />
        </Switch>
      </div>
    </BrowserRouter>
  )
}

export default Routers