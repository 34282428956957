import React, { useState, useEffect } from "react";
import SearchBar from "./SearchBar";
import SearchResult from "./SearchResult";
import CircularProgress from "@material-ui/core/CircularProgress";
import stylesheet from "../../stylesheet/searchbar.css";

export default function Home() {
  const intialCount = {
    docCount: 0,
    segmentCount: 0,
  };
  const [searchResult, setSearchResult] = useState(null);
  const [resultCount, setResultCount] = useState(intialCount);
  const [searchText, setSearchText] = useState(null);

  useEffect(() => {
    let docs = searchResult;
    console.log("searchResult", searchResult);
    setResultCount((prevState) => ({
      ...prevState,
      docCount:
        searchResult && docs.searchResult.hits && docs.searchResult.hits.hits
          ? docs.searchResult.hits.hits.length
          : 0,
    }));
  }, [searchResult]);

  const handleSearch = (e) => {
    setResultCount((prevState) => ({
      ...prevState,
      docCount: 0,
      segmentCount: 0,
    }));
    fetchDocuments();
  };

  const changeSearchText = (e) => {
    setSearchText(e.target.value);
  };

  const showSpinner = () => {
    let spinner = document.getElementById("spinner");
    spinner.classList.remove("hide-spinner");
    spinner.classList.add("show-spinner");
  };

  const hideSpinner = () => {
    let spinner = document.getElementById("spinner");
    spinner.classList.remove("show-spinner");
    spinner.classList.add("hide-spinner");
  };
  async function fetchDocuments() {
    showSpinner();

    let data = new FormData();
    data.append("question", searchText);

    console.log("Search", searchText);
    var urlFetch =
      "https://matinsights.azurewebsites.net/search?query=" + searchText;
    console.log("urlFetch", urlFetch);

    //await fetch('http://cracking.azurewebsites.net/search?query=22cr%20tube%20failre', {
    await fetch(urlFetch, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // body: JSON.stringify({query: searchText})
    })
      .then((response) => response.json())
      .then((data) =>
        setSearchResult(
          (prevState) => ({ ...prevState, searchResult: data }),
          hideSpinner()
        )
      )
      .catch(console.log("error"));
  }

  return (
    <>
      <SearchBar
        onSearchClick={handleSearch}
        changeSearchText={changeSearchText}
      ></SearchBar>
      <br></br>
      <div id="spinner" className="hide-spinner">
        <CircularProgress disableShrink />
      </div>
      {resultCount.docCount > 0 ? (
        <SearchResult
          searchResult={searchResult}
          resultCount={resultCount}
        ></SearchResult>
      ) : (
        <> </>
      )}
    </>
  );
}
