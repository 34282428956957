import actions from "../actions";

const Reducer = (state, action) => {
  console.log("state, action============>", state, action);
  let { type, payload } = action;
  switch (type) {
    case actions.SET_CRATopSide:
      return {
        ...state,
        craTopSide: payload
      };
   
    case actions.SET_Banner:
      return {
        ...state,
        banner: payload
      };
    // case "ADD_POST":
    //   return {
    //     ...state,
    //     posts: state.posts.concat(action.payload)
    //   };
    // case "REMOVE_POST":
    //   return {
    //     ...state,
    //     posts: state.posts.filter(post => post.id !== action.payload)
    //   };
    default:
      return state;
  }
};

export default Reducer;
