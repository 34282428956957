// axiosConfig.js

import axios from 'axios';
const token = localStorage.getItem('accessToken');

// Set common headers for all Axios requests
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;  // Replace with your access token


// Add a response interceptor for 401 and 500 errors
axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        if (error.response.status === 401) {
          // Handle 401 error (session expired)
          // Show popup and redirect to /login page
          showSessionExpiredPopup();
        } else if (error.response.status === 500) {
          // Handle 500 error
          // Show popup with an appropriate message
          showErrorPopup("Internal Server Error");
        }
      } else if (error.request) {
        // The request was made but no response was received
        // This can happen if the request times out
        showErrorPopup("Request Timed Out");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error', error.message);
        
        
      }
  
      return Promise.reject(error);
    }
  );
  
  export default axios;

  
  
  // Helper function to show the session expired popup
  function showSessionExpiredPopup() {
    // Your code to show the popup goes here
    alert("Session Expired. Please log in again.");
    // Redirect to the login page
    localStorage.clear();
    window.location.href = '/';
  }
  
  // Helper function to show an error popup with a custom message
  function showErrorPopup(message) {
    // Your code to show the error popup goes here
    alert(`Error: ${message}`);
    window.location.href = '/';

  }
