import React from 'react'
import ContactCard from './ContactCard'

const SubmitNewData = () => {
  return (
    <div style={{display:"flex",justifyContent:"center"}}>
        <ContactCard title="To include new data please contact" desc={{
        Name:"Anupom Sabhapondit",
        Designation:"Senior Material and Corrosion Engineer",
        Office:"Shell Business Operations STC-BNG",
        Email:"anupom.sabhapondit@shell.com",
        Phone:"+91 9901920179"}}/>
    </div>
  )
}

export default SubmitNewData