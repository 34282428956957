import { Box, Button, Checkbox, CircularProgress, Grid, TextField, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Plot from 'react-plotly.js'
import SolubilityChart from './SolubilityChart';
import axios from '../common/AxiosConfig';
const SolubilityParam = () => {
    const [solventOptions, setSolventOptions] = useState([])
    const [solventSelected, setSolventSelected] = useState({ Homopolymer: "", value: "" })
    const [polymerOption, setPolymerOption] = useState([])
    const [polymerSelected, setPolymerSelected] = useState({ Homopolymer: "", value: "" })
    const [trace1, setTrace1] = useState([])
    const [trace2, setTrace2] = useState([])
    const [isLoading, setIsLoading] = useState(false)


    //get data from api


    useEffect(() => {
        let url = 'https://digipol.azurewebsites.net/homopolymers_data_str'
        // setLoading(true)
  const token = localStorage.getItem('accessToken');
  setIsLoading(true)
        axios({
            method: "GET",
            url: url,
              headers: {
        Authorization: `Bearer ${token}`,
    },
        }).then(res => {
            // setLoading(false)
            setPolymerOption(res?.data?.data)
        setIsLoading(false)

            // setFilteredPosts(res?.data?.data)
        })

    }, [])

    useEffect(() => {
        let url = 'https://digipol.azurewebsites.net/solvents_data'
        setIsLoading(true)
        // setLoading(true)
  const token = localStorage.getItem('accessToken');

        axios({
            method: "GET",
            url: url,
                   headers: {
        Authorization: `Bearer ${token}`,
    },
        }).then(res => {
            // setLoading(false)
            setSolventOptions(res?.data?.data)
        setIsLoading(false)

            // setFilteredPosts(res?.data?.data)
        })

    }, [])

    const handlePolymer = (e, value) => {
        let temp = [...polymerOption]
        let id_filter = value;
        var filtered = temp.filter(function (item) {
            return id_filter.indexOf(item.Homopolymer) !== -1;
        });
        setPolymerSelected(filtered)
    }
    const handleSolvent = (e, value) => {
        let temp = [...solventOptions]
        let id_filter = value;
        var filtered = temp?.filter(function (item) {
            return id_filter?.indexOf(item.Solvent) !== -1;
        });
        setSolventSelected(filtered)

    }

    if(isLoading){
        return <div> <Box sx={{ display: 'flex' }} style={{display:"flex",margin:"50px",justifyContent:"center"}}>
        <CircularProgress />
      </Box></div>
    }

    return (
        <div>
             <fieldset style={{ border: "1px solid #ddd", borderRadius: "8px", padding: "16px" }}>
    <legend style={{ fontWeight: 'bold', color: 'gray' }}>Filter</legend>
    <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={6} lg={6}>
            <Autocomplete
                ß
                autoComplete='off'
                sx={{ width: '100%' }}
                options={polymerOption.map(x => x.Homopolymer)}
                onChange={(e, newValue) => handlePolymer(e, newValue)}
                autoHighlight
                renderTags={(selected) => (
                    <Typography noWrap color="textPrimary">
                        {selected.join(", ")}
                    </Typography>
                )}
                getOptionLabel={(option) => option}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                        {option}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Homopolymer"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                        required
                        errorMessages={["This field is required"]}
                    />
                )}
            />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
            <Autocomplete
                multiple
                autoComplete='off'
                sx={{ width: '100%' }}
                options={solventOptions.map(x => x.Solvent)}
                onChange={(e, newValue) => handleSolvent(e, newValue)}
                autoHighlight
                renderTags={(selected) => (
                    <Typography noWrap color="textPrimary">
                        {selected.join(", ")}
                    </Typography>
                )}
                getOptionLabel={(option) => option}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                        {option}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Solvent"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                        required
                        errorMessages={["This field is required"]}
                    />
                )}
            />
        </Grid>
    </Grid> 
</fieldset>




            {solventSelected.length>0 && polymerSelected.length>0 &&
            <Grid container spacing={2} style={{marginTop:"10px"}}>
  <Grid item xs={12} md={12}>

<SolubilityChart key={`${solventSelected}-${polymerSelected}`}  solventSelected={solventSelected} polymerSelected={polymerSelected}/>
 
</Grid>
</Grid>
}
        </div>
    )
}

export default SolubilityParam