import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import ResistanceOutput from "./ResistanceOutput";
import { CSVLink } from "react-csv";
import Checkbox from "@material-ui/core/Checkbox";
import { useStyles } from "./utils";
import axios from "../../common/AxiosConfig";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import DropdownInput from "../../common/DropdownInput";

const QualitativeBubbleCopy = (props) => {
  const [tableHeaders, setTableHeaders] = useState([]);
  const [updatedHeader, setUpdatedHeader] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const classes = useStyles();
  const [filteredPosts, setFilteredPosts] = React.useState([]);
  const [select, setSelect] = useState([]);
  const [polymerOption, setPolymerOption] = useState([]);
  const [exposureOption, setExposureOption] = useState([]);
  const [tradeOption, setTradeOption] = useState([]);
  const [sourceReportOption, setSourceReportOption] = useState([]);
  const [selected, setSelected] = useState([]);
  const [tradeNameSelected, setTradeNameSelected] = useState([]);
  const [exposureSelected, setExposureSelected] = useState([]);
  const [sourceReportSelected, setSourceReportSelected] = useState([]);
  const [concenteration, setConcenteration] = useState([]);
  const [temperature, setTemperature] = useState([]);
  const [resistance, setResistance] = useState([]);
  const [DpData, setDpData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(true);
  const [axisInput, setAxisInput] = useState({ x: [], y: [] });
  const [contourData, setContourData] = useState([]);
  const [exposureFlag, setExposureFlag] = useState(true);
  const form = React.createRef();
  let exposure = [];
  let reportOption = [];
  let tradeNameOption = [];

  useEffect(() => {
    var params = new URLSearchParams();

    if (Array.isArray(selected)) {
      selected.map((item) => params.append("pol", item));
    } else {
      params.append("pol", selected);
    }
    var request = {
      params: params,
    };
    let url = "";
    setLoading(true);
    if (selected?.length > 0) {
      url = `https://digipol.azurewebsites.net/dp_qual_by_polymer`;
    } else {
      url = "https://digipol.azurewebsites.net/dp_qual_polymers";
    }

    const token = localStorage.getItem("accessToken");

    axios({
      method: "GET",
      url: url,
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      setLoading(false);
      if (selected?.length > 0) {
        console.log("res", res?.data);
        setDpData(res?.data?.data);
      } else {
        setPolymerOption(res?.data);
      }
      // setFilteredPosts(res?.data?.data)
    });
  }, [props.chartType, selected]);
  console.log("DpData", DpData);

  useEffect(() => {
    if (
      selected?.length > 0 &&
      !tradeOption?.join("")?.length > 0 &&
      !tradeNameSelected?.length > 0
    ) {
      setExposureFlag(false);
      console.log("ExposureFlag1");
    } else if (
      selected?.length > 0 &&
      tradeOption?.join("")?.length > 0 &&
      tradeNameSelected?.length > 0
    ) {
      setExposureFlag(false);
      console.log("ExposureFlag2");
    } else {
      setExposureFlag(true);
    }
  }, [selected, tradeNameSelected, tradeNameOption]);

  useEffect(() => {
    //Filter options updated so apply all filters here
    let result = [...DpData];
    // result=selected.length>0? filterPolymer(result,"Polymer",selected) :result

    // result=tradeNameSelected?.length>0 && !tradeNameSelected?.includes("No Data")? filterPolymer(result,"Trade name",tradeNameSelected) :result
    // if(props.chartType==='bubble'){

    result =
      tradeNameSelected?.length > 0
        ? filterPolymer(result, "Polymer (Trade name)", tradeNameSelected)
        : result;
    // }
    // else{
    //   result=tradeNameSelected?.length>0 ? filterPolymer(result,"Trade name",tradeNameSelected) :result

    // }
    console.log("result", result);
    result =
      exposureSelected?.length > 0
        ? filterPolymer(result, "Exposure medium", exposureSelected)
        : result;
    result =
      sourceReportSelected?.length > 0
        ? filterPolymer(result, "Report no.", sourceReportSelected)
        : result;
    setFilteredPosts(result);
    // setDpData(result)
  }, [
    exposureSelected,
    selected,
    sourceReportSelected,
    tradeNameSelected,
    polymerOption,
    DpData,
  ]);
  console.log("filterpost", filteredPosts);
  useEffect(() => {
    tradeNameOption = getTradeNameValues("Polymer")?.sort((a, b) => a - b);

    exposure = getExposureMedium("Exposure medium")?.sort((a, b) => a - b);
    reportOption = getSourceReportValues("Report source", "Report no.")?.sort(
      (a, b) => a - b
    );
    setTradeOption(tradeNameOption);
    setExposureOption(exposure);
    setSourceReportOption(reportOption);
  }, [
    DpData,
    selected,
    sourceReportSelected,
    tradeNameSelected,
    exposureSelected,
    polymerOption,
  ]);

  console.log("setSourceReportOption: ", sourceReportSelected);
  console.log("tradeOption", tradeOption);
  const getTradeNameValues = (label) => {
    var filtered = DpData?.filter(function (item) {
      //   return selected.indexOf(item[`${label}`]) !== -1
      return selected?.indexOf(item["Polymer"]) !== -1;
    });
    console.log("filtered", filtered);
    let arr = filtered?.filter((item) => item["Concentration (vol%)"]);
    let permittedValues = [];
    // if(props.chartType==='bubble'){

    permittedValues = arr?.map((value) => value[`Polymer (Trade name)`]);
    // }
    // else{

    //    permittedValues = arr?.map(value => value[`Trade name`]);
    // }
    // const newArr = permittedValues?.map(item => typeof(item) == "undefined"? 'NO DATA' : item);
    let uniqueArray = permittedValues?.filter(function (item, pos) {
      return permittedValues?.indexOf(item) === pos;
    });
    return uniqueArray;
  };
  const getSourceReportValues = (label1, label2) => {
    let filtered = [];
    if (tradeNameSelected?.length === 0) {
      filtered = DpData?.filter(function (item) {
        return (
          selected?.indexOf(item["Polymer"]) !== -1 &&
          exposureSelected &&
          exposureSelected?.indexOf(item["Exposure medium"]) !== -1
        );
      });
    } else {
      // if(props.chartType==='bubble'){

      filtered = DpData?.filter(function (item) {
        return (
          selected?.indexOf(item["Polymer"]) !== -1 &&
          tradeNameSelected &&
          tradeNameSelected?.indexOf(item["Polymer (Trade name)"]) !== -1 &&
          exposureSelected &&
          exposureSelected?.indexOf(item["Exposure medium"]) !== -1
        );
      });
      // }
      //   else{

      //     filtered = DpData?.filter(function(item) {
      //      return selected?.indexOf(item['Polymer']) !== -1 && tradeNameSelected&&tradeNameSelected?.indexOf(item['Trade name']) !== -1  && exposureSelected&&exposureSelected?.indexOf(item['Exposure medium']) !== -1   ;
      //  });
      //   }
    }

    let arr = filtered?.filter((item) => item["Concentration (vol%)"]);
    let permittedValues = arr?.map((value) => {
      if (value[`Source Type`] == "Internal") {
        return value[`${label2}`];
      } else {
        return value[`${label1}`];
      }
    });
    let uniqueArray = permittedValues?.filter(function (item, pos) {
      return permittedValues?.indexOf(item) == pos;
    });
    return uniqueArray;
  };
  console.log("exposureFlag", exposureFlag);
  const getExposureMedium = (label) => {
    let filtered = [];
    if (tradeNameSelected?.length === 0) {
      filtered = DpData?.filter(function (item) {
        return selected && selected?.indexOf(item["Polymer"]) !== -1;
      });
    } else {
      // if(props.chartType==='bubble'){
      filtered = DpData?.filter(function (item) {
        return (
          selected &&
          selected?.indexOf(item["Polymer"]) !== -1 &&
          tradeNameSelected &&
          tradeNameSelected?.indexOf(item["Polymer (Trade name)"]) !== -1
        );
      });

      // }
      //   else{

      //     filtered = DpData?.filter(function(item) {
      //      return selected&&selected?.indexOf(item['Polymer']) !== -1 && tradeNameSelected&&tradeNameSelected?.indexOf(item['Trade name']) !== -1  ;
      //  });
      //   }
    }
    let arr = filtered?.filter((item) => item["Concentration (vol%)"]);
    let permittedValues = arr?.map((value) => value[`${label}`]);
    console.log("permittedValues", permittedValues);
    let uniqueArray = permittedValues?.filter(function (item, pos) {
      return permittedValues?.indexOf(item) == pos;
    });
    return uniqueArray;
  };
  console.log("tradeNameSelected", tradeNameSelected);
  const getPolymer = (label) => {
    let permittedValues = DpData?.map((value) => value[`${label}`]);
    let uniqueArray = permittedValues?.filter(function (item, pos) {
      return permittedValues?.indexOf(item) == pos;
    });
    return uniqueArray;
  };

  useEffect(() => {
    const result = Object.keys(Object.assign({}, DpData[0]));
    // let replacedItem = result?.map((item) =>
    //   item == "Temperature (C)" ? "Temperature (\u00B0C)" : item
    // );
    setUpdatedHeader(result);

    setTableHeaders(result);
  }, [DpData]);

  const filterPolymer = (arr, label, temp) => {
    // return arr.filter((person) => temp.includes(person[`${label}`]))
    return arr.filter((person) => temp.includes(person[`${label}`]));
  };

  const handleChange = (event, value) => {
    setFlag(true);
    setSelected(value);
    setTradeNameSelected([]);
    setExposureSelected([]);
    setSourceReportSelected([]);
  };

  const handleTradeName = (event, value) => {
    setFlag(true);
    setTradeNameSelected(value);

    setExposureSelected([]);
    setSourceReportSelected([]);
  };

  const handleExposure = (event, value) => {
    setFlag(true);
    setExposureSelected(value);
    setSourceReportSelected([]);
  };
  const handleSourceReport = (event, value) => {
    setFlag(true);
    setSourceReportSelected(value);
  };
  console.log("sourceReportSelected: ", sourceReportSelected);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function stableSort(array) {
    const stabilizedThis = array?.sort((a, b) => a["DP ID"] - b["DP ID"]);
    return stabilizedThis;
  }

  const getValues = (label) => {
    let permittedValues = filteredPosts.map((value) => value[`${label}`]);
    let uniqueArray = permittedValues.filter(function (item, pos) {
      return permittedValues.indexOf(item) == pos;
    });
    return permittedValues;
  };

  const handleSubmit = () => {
    if (props.chartType === "bubble") {
      setLoading(true);
      setConcenteration(getValues("Concentration (vol%)"));
      setTemperature(getValues("Temperature (C)"));
      setResistance(getValues("Resistance"));
      setFlag(false);
      setLoading(false);
    } else {
      // setLoading(true)
      let temp = getValues("Resistance")?.sort((a, b) => a - b);
      getContourResult();
      setAxisInput({
        x: getValues("Concentration (vol%)"),
        y: getValues("Temperature (C)"),
      });
      setResistance(temp);
      setFlag(false);
      //   setLoading(false)
    }
  };

  const getColor = (data) => {
    // enter your conditional coloring code here
    switch (data) {
      case 0:
        return "silver";
      case 1:
        return "#D93028";
      case 2:
        return "#F36642";
      case 3:
        return "#FBAB62";
      case 4:
        return "#FDE58F";
      case 5:
        return "#D8EE8A";
      case 6:
        return "#A3D969";
      case 7:
        return "#60BB60";
      case 8:
        return "#18934D";
      case 9:
        return "#1A924D";
      case 10:
        return "#066D36";
    }
  };
  // let tempRes=[...resistance]
  let tempRes = [...resistance];
  tempRes = tempRes?.map((item) => (item == 0 ? 5 : item));
  const validating = ["required"];

  const getContourResult = (input) => {
    setLoading(true);
    let params = {};
    let URL = "";
    const token = localStorage.getItem("accessToken");

    if (
      tradeOption?.join("").length > 0 &&
      props.chartType === "qualitativeContour"
    ) {
      URL = `https://digipol.azurewebsites.net/get_matrix?name=DP_qual&pol=${selected}&trade=${tradeNameSelected}&exp=${exposureSelected}&source=${sourceReportSelected}&srctype=${filteredPosts[0]["Source Type"]}`;
    } else {
      URL = `https://digipol.azurewebsites.net/get_matrix?name=DP_qual&pol=${selected}&exp=${exposureSelected}&source=${sourceReportSelected}&srctype=${filteredPosts[0]["Source Type"]}`;
    }
    axios({
      method: "GET",
      url: URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      setLoading(false);
      setContourData(res?.data);
    });
  };

  let customizeData = filteredPosts?.map((item) => {
    return {
      pol: item["Polymer (Trade name)"],
      exp: item["Exposure medium"],
      res: item["Resistance"],
    };
  });

  const resetHandler = () => {
    setSelected([]);
    setTradeNameSelected([]);
    setExposureSelected([]);
    setSourceReportSelected([]);
    setFlag(true);
  };
  return (
    <>
      <fieldset style={{ height: "auto", display: "flex" }}>
        <legend align="left" style={{ fontWeight: "bold", color: "gray" }}>
          {" "}
          Filter{" "}
        </legend>
        {/* <Box> */}
        <ValidatorForm
          ref={form}
          onSubmit={handleSubmit}
          onError={(errors) => console.log(errors)}
          noValidate={true}
        >
          <Grid container spacing={2}>
            <>
              <Grid item xs={12} md={4}>
                <DropdownInput
                  classes={classes}
                  selected={selected}
                  polymerOption={polymerOption}
                  handleChange={handleChange}
                  label="Polymer"
                  loading={loading}
                  validating={validating}
                  multiple={props.chartType == "bubble" ? true : false}
                  withCheckboxes={props.chartType == "bubble" ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DropdownInput
                  classes={classes}
                  selected={tradeNameSelected}
                  polymerOption={tradeOption}
                  handleChange={handleTradeName}
                  loading={false} // You can set loading state as per your requirement
                  label="Trade Name"
                  validating={validating}
                  multiple={props.chartType == "bubble" ? true : false}
                  withCheckboxes={props.chartType == "bubble" ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DropdownInput
                  classes={classes}
                  selected={exposureSelected}
                  polymerOption={exposureOption}
                  handleChange={handleExposure}
                  loading={false} // You can set loading state as per your requirement
                  label="Exposure Medium"
                  multiple={props.chartType == "bubble" ? true : false}
                  validating={validating}
                  withCheckboxes={props.chartType == "bubble" ? true : false}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <DropdownInput
                  classes={classes}
                  selected={sourceReportSelected}
                  polymerOption={sourceReportOption
                    .filter((x) => x !== "")
                    .sort()}
                  handleChange={(e, newValue) =>
                    setSourceReportSelected(newValue)
                  }
                  loading={loading}
                  validating={validating}
                  label="Source report"
                  multiple={props.chartType == "bubble" ? true : false}
                  withCheckboxes={props.chartType == "bubble" ? true : false}
                />
              </Grid>
            </>
            {/* )
    })} */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Button
                id="btnPhCalcPredict"
                variant="contained"
                color="primary"
                type="submit"
              >
                {" "}
                Submit
              </Button>
              <Button
                id="btnPhCalcReset"
                style={{ marginLeft: "20px" }}
                onClick={resetHandler}
                variant="contained"
                color="primary"
              >
                {" "}
                Clear
              </Button>
              <br></br>
            </div>
          </Grid>
        </ValidatorForm>
        {/* </Box> */}
      </fieldset>
      {!loading ? (
        <Box style={{ marginTop: "10px" }}>
          {!flag ? (
            <Grid container>
              <Grid item xs={12} md={12}>
                {/* <Delayed> */}
                {props.chartType === "bubble" ? (
                  <Plot
                    var
                    data={[
                      {
                        // x: [40, 60, 80, 100],
                        // y: [40, 60, 80, 100],
                        x: concenteration,
                        y: temperature,
                        mode: "markers",
                        customdata: customizeData,
                        hovertemplate:
                          "%{xaxis.title.text}: %{x}<br>" +
                          "%{yaxis.title.text}: %{y}<br>" +
                          "Resistance: %{customdata.res}<br>" +
                          "Polymer: %{customdata.pol}<br>" +
                          "Exposure Medium: %{customdata.exp}<br>" +
                          "<extra></extra>",
                        marker: {
                          showscale: true,

                          size: tempRes?.map((data) =>
                            data < 4 ? data * 6 : data * 4
                          ),

                          cmax: 10,
                          cmin: 0,
                          colorbar: {
                            title: "Chemical Resistance",
                            titleside: "right",
                            titlefont: {
                              size: 12,
                              family: "Arial, sans-serif",
                            },
                            tick0: 0,
                            dtick: 1,
                            tickmode: "array",
                            tickvals: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                            ticktext: [
                              "No Data",
                              1,
                              2,
                              3,
                              4,
                              5,
                              6,
                              7,
                              8,
                              9,
                              10,
                            ],
                            // nticks:20,
                          },
                          // color:[0,1],
                          color: filteredPosts.map((d) =>
                            getColor(d["Resistance"])
                          ),
                          // color:getColor(resistance),
                          // color: [28.666666666666668, 20.666666666666668, 15.333333333333334,         17.666666666666668, 19.0, 17.666666666666668, 26.0, 21.0, 21.666666666666668, 27.0, 21.666666666666668, 16.666666666666668, 27.0, 14.0, 29.666666666666668, 22.0, 16.0, 28.0, 27.0, 25.333333333333332],
                          colorscale: [
                            [0, "Silver"],
                            [0.085, "silver"],
                            [0.111, "#D93028"],
                            [0.222, "#F36642"],
                            [0.333, "#FBAB62"],
                            [0.444, "#FDE58F"],
                            [0.556, "#D8EE8A"],
                            [0.667, "#A3D969"],
                            [0.778, "#60BB60"],
                            [0.889, "#18934D"],
                            [0.989, "#066D36"],
                            [1.0, "#066D36"],
                          ],
                        },
                        type: "scattergl",
                      },
                    ]}
                    useResizeHandler={true}
                    style={{ width: "100%", height: "100%" }}
                    let
                    layout={{
                      title: {
                        text: `<b>Polymer</b> : ${selected}  <b>Trade Name</b> : ${tradeNameSelected}  <b>Exposure Medium</b> : ${exposureSelected}  <b>Source Report</b> : ${sourceReportSelected}`,
                        font: {
                          family: "Open Sans, verdana, arial, sans-serif",
                          size: 12,
                          fontWeight: 400,
                        },
                        xref: "paper",
                        x: 0.05,
                      },
                      hovermode: "closest",
                      xaxis: {
                        title: "Concentration (vol %)",

                        titlefont: {
                          family: "Arial, sans-serif",
                          size: 12,
                          color: "black",
                        },
                      },
                      yaxis: {
                        title: "Temperature (\u00B0C)",
                        titlefont: {
                          family: "Arial, sans-serif",
                          size: 12,
                          color: "black",
                        },
                      },
                      autosize: true,
                      
                      margin: {
                        l: 50,
                        r: 50,
                        b: 100,
                        t: 100,
                        pad: 20,
                      },
                      automargin: true,
                    }}
                  />
                ) : (
                  <Plot
                    var
                    data={[
                      {
                        x: contourData?.x,
                        y: contourData?.y,
                        z: contourData.z,
                        zmin: 0,
                        contours: {
                          start: 0,
                          end: 10,
                          size: 1,
                        },
                        colorbar: {
                          ticks: "outside",
                          title: "Chemical Resistance",
                          titleside: "right",
                          titlefont: {
                            size: 12,
                            family: "Arial, sans-serif",
                          },
                          dtick: 1,
                          tickwidth: 2,
                          ticklen: 10,
                          tickcolor: "grey",
                          showticklabels: true,
                          tickfont: {
                            size: 15,
                          },
                          xpad: 50,
                          tick0: 0,
                          dtick: 1,
                          tickmode: "array",
                          tickvals: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], // Define tick values
                          ticktext: [
                            "No Data",
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                            "7",
                            "8",
                            "9",
                            "10",
                          ], // Define tick labels
                        },
                        colorscale: [
                          [0.0, "silver"],
                          [0.085, "silver"],
                          [0.111, "#D93028"],
                          [0.222, "#F36642"],
                          [0.333, "#FBAB62"],
                          [0.444, "#FDE58F"],
                          [0.556, "#D8EE8A"],
                          [0.667, "#A3D969"],
                          [0.778, "#60BB60"],
                          [0.889, "#18934D"],
                          [0.989, "#066D36"],
                          [1.0, "#066D36"],
                        ],
                        type: "contour",
                        hovertemplate:
                          "%{xaxis.title.text}: %{x}<br>Temperature (\u00B0C): %{y}<br>Chemical resistance: %{z}<br><extra></extra>",
                        line: {
                          width: 0,
                        },
                        dx: 10,
                        x0: 5,
                        dy: 10,
                        y0: 10,
                      },
                    ]}
                    useResizeHandler={true}
                    style={{ width: "100%", height: "100%" }}
                    let
                    layout={{
                      legend: { orientation: "h" },

                      title: {
                        text: `<b>Polymer</b> : ${selected}  <b>Trade Name</b> : ${tradeNameSelected}  <b>Exposure Medium</b> : ${exposureSelected}  <b>Source Report</b> : ${sourceReportSelected}`,
                        font: {
                          family: "Courier New, monospace",
                          size: 14,
                          color: "black",
                        },
                        xref: "paper",
                        x: 0.05,
                      },
                      hovermode: "closest",
                      xaxis: {
                        title: {
                          text: "Concentration (vol%) ",
                          standoff: 10,
                        },

                        titlefont: {
                          family: "Arial, sans-serif",
                          size: 13,
                          color: "black",
                        },
                        // range: [0, Math.max.apply(null, axisInput.x) ]
                      },
                      yaxis: {
                        title: "Temperature (\u00B0C)",
                        titlefont: {
                          family: "Arial, sans-serif",
                          size: 13,
                          color: "black",
                        },

                        // range: [0,  Math.max.apply(null, axisInput.y)]
                      },

                      autosize: true,
                      // width: 1400,
                      // height: 700,
                      // width: 1200,
                      // height: 700,
                      margin: {
                        l: 50,
                        r: 50,
                        b: 100,
                        t: 100,
                        pad: 20,
                      },
                      automargin: true,
                      responsive:true
                    }}
                
                  />
                )}
                {/* </Delayed> */}
              </Grid>
  
              
            </Grid>
          ) : null}
        </Box>
        
      ) : (
        <Box
          sx={{ display: "flex" }}
          style={{ display: "flex", margin: "50px", justifyContent: "center" }}
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && !flag ?
    <Grid item xs={12}>
    {filteredPosts && tableHeaders ? (
      <Box>
        <div
          id="divCSVDownload"
          style={{ float: "right", marginTop: "20px" }}
        >
          <CSVLink
            filename={"DP" + ".csv"}
            data={filteredPosts}
            headers={updatedHeader}
          >
            <Button variant="contained" color="primary">
              Export Excel
            </Button>
          </CSVLink>
        </div>
        <br></br>
        <br></br>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    {updatedHeader?.map((data, i) => (
                      <TableCell key={i} align="right">
                        {data}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredPosts &&
                    stableSort(filteredPosts)
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((row, index) => {
                        // const isItemSelected = isSelected(row.name);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={row.name}
                          >
                            {tableHeaders.map((element, j) => (
                              <TableCell align="center">
                                {row[`${element}`]}
                              </TableCell>
                            ))}
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredPosts ? filteredPosts.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </Box>
    ) : (
      <></>
    )}
  </Grid>:null  
    }
    </>
  );
};

export default QualitativeBubbleCopy;
