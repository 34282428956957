import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Plot from "react-plotly.js";

const SpherePlot = ({
  solventSelected,
  polymerSelected,
  key,
  hansenResponse,
}) => {
  // State to store solvent and polymer values
  const [solventData, setSolventData] = useState([]);
  const [polymerData, setPolymerData] = useState([]);

  // Update state with props data
  useEffect(() => {
    setSolventData(solventSelected);
    setPolymerData(polymerSelected);
  }, [solventSelected, polymerSelected]);

  // State variables to store sphere and point coordinates
  const [spheres, setSpheres] = useState([]);
  const [points, setPoints] = useState([]);
  const [distance, setDistance] = useState([]);

  useEffect(() => {
    // Calculate spheres for polymer data
    const sphereComponents = polymerSelected.map((polymer) => {
      const center_x = polymer["∂d"];
      const center_y = polymer["∂p"];
      const center_z = polymer["∂h"];
      const radius = polymer.R;
  
      const theta = Array.from({ length: 100 }, (_, i) => (i / 99) * 2 * Math.PI);
      const phi = Array.from({ length: 50 }, (_, i) => (i / 49) * Math.PI);
      
      const x_sphere = [];
      const y_sphere = [];
      const z_sphere = [];
      
      phi.forEach((phiVal) => {
        theta.forEach((thetaVal) => {
          x_sphere.push(center_x + radius * Math.sin(phiVal) * Math.cos(thetaVal));
          y_sphere.push(center_y + radius * Math.sin(phiVal) * Math.sin(thetaVal));
          z_sphere.push(center_z + radius * Math.cos(phiVal));
        });
      });
      
      const x_2d = Array.from({ length: phi.length }, (_, i) =>
        x_sphere.slice(i * theta.length, (i + 1) * theta.length)
      );
      const y_2d = Array.from({ length: phi.length }, (_, i) =>
        y_sphere.slice(i * theta.length, (i + 1) * theta.length)
      );
      const z_2d = Array.from({ length: phi.length }, (_, i) =>
        z_sphere.slice(i * theta.length, (i + 1) * theta.length)
      );
      
      return {
        type: "surface",
        x: x_2d,
        y: y_2d,
        z: z_2d,
        opacity: 0.5,
        showscale: false,
        colorscale: "blues",
        name: polymer?.Polymer,
      };
    });
  
    // Calculate points for solvent data
   // Calculate points for solvent data
   const pointComponents = solventSelected.flatMap((solvent, index) => {
    // Main trace without border
    let mainTrace = {
      type: "scatter3d",
      mode: "markers",
      x: [solvent["∂d"]],
      y: [solvent["∂p"]],
      z: [solvent["∂h"]],
      marker: {
        size: 12,
        // color: `rgb(${index * 10}, ${index * 20}, ${index * 30})`,
        opacity: 0.9
      },
      showlegend: true,
      name: `${solvent?.Solvents}(${hansenResponse.position[index]})`,
    };
  
    // Check if position is 'in' to add outlined markers
    if (hansenResponse.position[index] == 'in') {
      let outlinedTrace = {
        type: "scatter3d",
        mode: "markers",
        x: [solvent["∂d"]],
        y: [solvent["∂p"]],
        z: [solvent["∂h"]],
        marker: {
          size: 14, // Adjust size to make the outlined markers slightly larger
          color: 'transparent', // Make the main color transparent
          line: {
            width: 4, // Adjust the width of the border as needed
            color: 'red' // Set the color of the border
          }
        },
        showlegend: false,
      };
      return [mainTrace, outlinedTrace];
    } else {
      return [mainTrace];
    }
  });
  
  
    // Calculate distance from points to spheres
    const distances = [];
    pointComponents.forEach((pointComponent) => {
      const point_x = pointComponent.x[0];
      const point_y = pointComponent.y[0];
      const point_z = pointComponent.z[0];
  
      sphereComponents.forEach((sphereComponent) => {
        const center_x = sphereComponent.x[0][0];
        const center_y = sphereComponent.y[0][0];
        const center_z = sphereComponent.z[0][0];
  
        const distance = Math.sqrt(
          Math.pow(point_x - center_x, 2) +
          Math.pow(point_y - center_y, 2) +
          Math.pow(point_z - center_z, 2)
        );
        distances.push(distance.toFixed(2));
      });
    });
  
    // Set state with updated components and distances
    setSpheres(sphereComponents);
    setPoints(pointComponents);
    setDistance(distances);
  }, [solventSelected, polymerSelected, hansenResponse]);
  

  return (
    <Grid container>
      <Grid item sm={12} md={2}></Grid>
      <Grid item sm={12} md={8}>
        <div style={{ width: "100%", height: "100%" }}>
          <Plot
            data={[...points, ...spheres]}
            useResizeHandler={true}
            style={{ width: "100%", height: "600px" }} // Adjust height as needed
            layout={{
              scene: {
                xaxis: { title: "∂d" },
                yaxis: { title: "∂p" },
                zaxis: { title: "∂h" },
                aspectmode: "data",
              },
              hovermode: "closest",
              margin: {
                l: 50,
                r: 50,
                b: 100,
                t: 100,
                pad: 4, // Adjust padding as needed
              },
              responsive: true, // Enable responsive layout
            }}
          />
        </div>
      </Grid>
      <Grid item sm={2}></Grid>
    </Grid>
  );
};

export default SpherePlot;
