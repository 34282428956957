
import { FormControl, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, {useEffect} from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { useStyles } from '../qualitative compatibility/bubblechart/utils';
export default function AutocompleteFields(props) {
     const [value, setValue] = React.useState({data:null,isChange:false});
     const classes = useStyles();
    const handleInputChange = (event,value) => {
    
    
         setValue({data:value,isChange:!value?.isChange});
         
       // props.onSliderInputChange(props.data.id, event.target.value === '' ? '' : Number(event.target.value), '')
    
      };
      
      useEffect(() => {
        props.onInputChange(props.data.id, value?.data)
    
      }, [value])
    const validating=['required'];
console.log("optiosn",props.data.option)
    return (   <div>    
        
        <FormControl className={classes.formControl}>
      
      <Autocomplete
      
      id={props.data.id}
      value={props?.data?.value}

  autoComplete='off'
      sx={{ width: 300 }}
      options={props.data?.option?.filter(x=>x!=="")}
    //   onChange={(e, newValue) => setPressureSelected(newValue)}
      onChange={handleInputChange}
      autoHighlight
      // disabled={!props.data.option?.length > 0}
      disabled={!props.data.option?.join('').length > 0}
      
      renderTags={selected => {
        console.log("selected = ", selected);
        let renderTagsValue = selected
          .map(function(elem) {
            return elem;
          })
          .join(", ");
    
        return (
          <Typography noWrap={true} color="textPrimary">
            {renderTagsValue}
          </Typography>
        );
      }}
      getOptionLabel={(option) => typeof option === 'string'
                  || option instanceof String ? option : ""}
     
      renderInput={(params) => (
        <TextValidator
        {...params}
        label={props?.data?.displayName}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'off', // disable autocomplete and autofill
        }}
        required
        value={props?.data?.value}
        validators={props.data?.option?.join('').length > 0&&validating}
        errorMessages={["this field is required"]}
    
      />
      )}
    />
    
    </FormControl>
        
        
        
        
      </div>
    );

}