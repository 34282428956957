import React from "react";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";

export default function ProgessBarPercentage(props) {
  var score =props.score;
  var s=1;
  if (isNaN(score))
    score = 0;
  if (score > 100)
    score = 100;
  var scoreStatus = "active";
  if (score >= 70) {
    scoreStatus = "success";
  } else if (score >= 40) {
    scoreStatus = "active";
  } else {
    scoreStatus = "error";
  }
  return (
    <Progress
      percent={score}
      status={scoreStatus}
      style={{ width: "100px", float: "right" }}
      theme={{
        error: {
          symbol: score + "%",
          color: "red",
        },
        default: {
          symbol: score + "%",
          color: "blue",
        },
        active: {
          symbol: score + "%",
          color: "orange",
        },
        success: {
          symbol: score + "%",
          color: "green",
        },
      }}
    />
  );
}
