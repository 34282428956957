
import Axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";


import App from '../App'
import Logo from '../images/DigipolLogo.png';
var codeVerifier, pingURL, code_challenge;
var base64 = require('base64-js');
var HAS_CRYPTO = typeof window !== 'undefined' && !!window.crypto;
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loginscreen: [],
      pingURL: 'sso-dev',
      client_id:'OjVLydXkFaFDevQj',
      isLogin: true,
      isLoginSucess: null,
      userDetails:null
    };
    this.onLoadPingURL = this.onLoadPingURL.bind(this);
    this.getToken = this.getToken.bind(this);
    this.getURLParameter = this.getURLParameter.bind(this);
    this.getSourceUrl = this.getSourceUrl.bind(this);
  }
  

  getURLParameter(name, url) {
    if (!url) url = window.location.href;
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(url);
    return results == null ? null : results[1];

  }

  componentDidMount() {

    var hours = 1;
    var now = new Date().getTime();

    var setupTime = window.localStorage.getItem('setupTime');

    if (setupTime == null) {
      window.localStorage.setItem('setupTime', now)
    } else {
      if (now - setupTime > hours * 60 * 60 * 1000) {
        window.localStorage.clear()
        window.localStorage.setItem('setupTime', now);
      }
    }

    if (window.localStorage.getItem("email")) {
      var accesstoken =window.localStorage.getItem("accessToken1");
      this.setState({
        username: window.localStorage.getItem("email"),
        userDetails:  JSON.parse(atob(accesstoken))
      }, this.invokeLoginValidation(JSON.parse(atob(accesstoken)))); return;
    }
    if (this.getURLParameter("code", window.location.href)) {

      this.getToken();
    } else {
      this.getURL()
    }
  }

  getSourceUrl=()=>{
    return window.location.hostname
  }
  getToken() {
    // var digipolUrl="https://digipol.azurewebsites.net"
    // const currentURL = window.location.hostname;
    // if (currentURL === 'digipol.azurewebsites.net') {
    //   // Handle the case when the hostname is 'digipol.azurewebsites.net'
    //   digipolUrl= 'https://digipol.azurewebsites.net';
    // } else if (currentURL === 'digipol.shell.com') {
    //   // Handle the case when the hostname is 'digipol.shell.com'
    //   digipolUrl = 'https://digipol.shell.com';
    // } 
    var digipolUrl = "https://digipol.azurewebsites.net";
const currentURL = window.location.hostname;

if (currentURL === 'digipol.azurewebsites.net' || currentURL === 'digipol.shell.com') {
  // If the hostname matches, use it in the URL
  digipolUrl = 'https://' + currentURL;
}

    var state = this.getURLParameter("code", window.location.href);
    var details = {
      "client_id": this.state.client_id,
      "grant_type": "authorization_code",
      "redirect_uri": digipolUrl,//process.env.REACT_APP_SSO_APP_REDIRECT_URL,
      "code_verifier": window.localStorage.getItem("verifier"),
      "code": state
    };
    var formBody = [];
    for (var property in details) {
      var encodedKey = property;
      var encodedValue = details[property];
      formBody.push(encodedKey + "=" + encodedValue);
    }


    formBody = formBody.join("&");
    var url = new URL("https://" + 'sso-dev' + ".shell.com/as/token.oauth2");
    let searchParams = new URLSearchParams(details);
    searchParams.forEach((value, key) => {
      url.searchParams.append(key, value);
    });
    fetch("https://sso-dev.shell.com/as/token.oauth2",{
      method: 'POST', "dataType": "json",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formBody
    }).then(res => res.json()).then(
      (result) => {
        console.log('result', result);
        if (result.access_token) {
          window.localStorage.setItem("accessToken", result.access_token);
          var token = result.access_token.split('.')[1];
          window.localStorage.setItem("accessToken1", token);

          var parseJsonToken = JSON.parse(atob(token));
          console.log('getToken',parseJsonToken)

          if(parseJsonToken.hasOwnProperty("isMemberOf")){
                  if(typeof parseJsonToken.isMemberOf === "string"){
                      if (parseJsonToken.isMemberOf==='AZ-AATM-AS-GRP-PT-OjVLydXkFaFDevQj-01') {
                        if (parseJsonToken.mail) {
                          window.localStorage.setItem("userDetails", parseJsonToken);
              
                          var email = parseJsonToken.mail;
                          window.localStorage.setItem("email", email);
                          var givenName = parseJsonToken.givenName;
                          var sn = parseJsonToken.sn;
                          window.localStorage.setItem("displayName", givenName + " " + sn);
                          
                          this.setState({
                            username: email,
                            userDetails: parseJsonToken,
                          }, this.invokeLoginValidation(parseJsonToken));
                        } else {
                          alert("There is no Email Attached to your ID, Please contact PING Team.");
                        }
                      }
                      else{
                        this.setState({
                          isLoginSucess: false
                        });
                      }
                  }
                  else{
                          if(parseJsonToken.isMemberOf.includes('AZ-AATM-AS-GRP-PT-OjVLydXkFaFDevQj-01')){
                            if (parseJsonToken.mail) {
                              window.localStorage.setItem("userDetails", parseJsonToken);
                  
                              var email = parseJsonToken.mail;
                              window.localStorage.setItem("email", email);
                              var givenName = parseJsonToken.givenName;
                              var sn = parseJsonToken.sn;
                              window.localStorage.setItem("displayName", givenName + " " + sn);
                              
                              this.setState({
                                username: email,
                                userDetails: parseJsonToken,
                              }, this.invokeLoginValidation(parseJsonToken));
                            } else {
                              alert("There is no Email Attached to your ID, Please contact PING Team.");
                            }
                          }
                          else{
                            this.setState({
                              isLoginSucess: false
                            });
                          }
                  }
          }
          else{
            this.setState({
              isLoginSucess: false
            });
          }
          // if (parseJsonToken.mail) {
          //   window.localStorage.setItem("userDetails", parseJsonToken);

          //   var email = parseJsonToken.mail;
          //   window.localStorage.setItem("email", email);
          //   var givenName = parseJsonToken.givenName;
          //   var sn = parseJsonToken.sn;
          //   window.localStorage.setItem("displayName", givenName + " " + sn);
            
          //   this.setState({
          //     username: email,
          //     userDetails: parseJsonToken,
          //   }, this.invokeLoginValidation(parseJsonToken));
          // } else {
          //   alert("There is no Email Attached to your ID, Please contact PING Team.");
          // }
        } else {
          this.setState({
            isLoginSucess: false
          });
        }
      },
      (error) => { console.log(error)}
    );
  }
  onLoadPingURL = (state) => {
    console.log(state);

  }
  bufferToString = (buffer) => {
    var CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var state = [];
    for (var i = 0; i < buffer.byteLength; i += 1) {
      var index = buffer[i] % CHARSET.length;
      state.push(CHARSET[index]);
    }
    return state.join('');
  }
  generateRandom = (size) => {
    var that = this;
    var buffer = new Uint8Array(size);
    if (HAS_CRYPTO) {
      window.crypto.getRandomValues(buffer);
    }
    else {
      // fall back to Math.random() if nothing else is available
      for (var i = 0; i < size; i += 1) {
        buffer[i] = Math.random();
      }
    }
    return that.bufferToString(buffer);
  }
  urlSafe = (buffer) => {
    var encoded = base64.fromByteArray(new Uint8Array(buffer));
    return encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  }
  deriveChallenge = (code) => {
    var that = this;
    return new Promise(function (resolve, reject) {
      crypto.subtle.digest('SHA-256', that.textEncodeLite(code)).then(function (buffer) {
        return resolve(that.urlSafe(new Uint8Array(buffer)));
      }, function (error) { return reject(error); });
    });
  }
  base64URLEncode = (str) => {
    return str.toString('base64')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  }
  textEncodeLite(str) {
    var buf = new ArrayBuffer(str.length);
    var bufView = new Uint8Array(buf);
    for (var i = 0; i < str.length; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return bufView;
  }
  setupCodeVerifier = () => {
    var _this = this;
    codeVerifier = this.generateRandom(43);
    var challenge = this.deriveChallenge(codeVerifier).catch(function (error) {
      console.log(error);
      return undefined;
    });
    return challenge.then(function (result) {
      if (result) {
        code_challenge = result;
        _this.navToPing();
      }
    });
  }


  async getURL(urlObject) {
    await this.setupCodeVerifier();
    console.log(urlObject);
  }
  navToPing = () => {
    var challenge = code_challenge;
    // var digipolUrl="https://digipol.azurewebsites.net"
    // const currentURL = window.location.hostname;
    // if (currentURL === 'digipol.azurewebsites.net') {
    //   // Handle the case when the hostname is 'digipol.azurewebsites.net'
    //   digipolUrl= 'https://digipol.azurewebsites.net';
    // } else if (currentURL === 'digipol.shell.com') {
    //   // Handle the case when the hostname is 'digipol.shell.com'
    //   digipolUrl = 'https://digipol.shell.com';
    // } 
    var digipolUrl = "https://digipol.azurewebsites.net";
const currentURL = window.location.hostname;

if (currentURL === 'digipol.azurewebsites.net' || currentURL === 'digipol.shell.com') {
  // If the hostname matches, use it in the URL
  digipolUrl = 'https://' + currentURL;
}
    pingURL = "https://" + 'sso-dev' + ".shell.com" + "/as/authorization.oauth2?redirect_uri=" + digipolUrl + "&client_id=" + this.state.client_id + "&response_type=code&scope=email&audience=API_AUDIENCE"
      + "&access_type=offline" + "&code_challenge=" + challenge + "&code_challenge_method=S256";
    window.localStorage.setItem("verifier", codeVerifier);
    window.location.href = pingURL;

  }

  invokeLoginValidation(userDetails) {
    console.log('invokeLoginValidation',userDetails)

    let _that = this;
    var obj = {};
    obj["emailAddress"] = window.localStorage.getItem("email");
    this.setState({
      userDetails: userDetails,
      isLoginSucess: true
    });
    _that.props.history.push({ pathname: "/home" });


  }

  handleClick = event => {
    event.preventDefault();
    this.invokeLoginValidation(this.state.userDetails);
  };
  render() {

    return (
      <div className="container">
        {this.state.isLoginSucess === null && <></>}
        {/* {this.state.isLoginSucess === true && <App userDetails={this.state.userDetails}></App>} */}
        
        {this.state.isLoginSucess === false &&
        //  <div className='authErrorDiv'>
         
        //   <h1 style={{ textAlign: 'center' }}> Not sfsdgdgfds Authorized</h1>
        //   <p>Sorry, you are not authozired to view the requested site. Please contant the site administrator for access.</p>
        // </div>
        
        <div className='authErrorDiv'>
        <img
          style={{ height: 90, marginLeft: '36%' }}
          src={Logo}
          alt="Digipol"
        ></img>
        <h1 style={{ textAlign: 'center' }}> Not Authorized</h1>
        <p>Sorry, you are not authozired to view the requested site. Please contact site administrator for access (Fatyga, Bartlomiej N SSSC-PTIY/FHB).</p>
      </div>
        }
      </div>

    );
  }
}

export default withRouter(Login);



