import * as React from 'react';
import { Box,Button,Grid,Slide,Snackbar, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert/Alert';





function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}
const useStyles = makeStyles({
  root: {
background:'lightyellow',
opacity:"0.9",
color:"black"
  }
})

export default function SnackbarComp() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    open: true,
    vertical: 'bottom',
    horizontal: 'right',
  });
  const [transition, setTransition] = React.useState(undefined);
  const { vertical, horizontal, open } = state;

  const handleClick = (newState) => () => {
    setState({ ...newState, open: true });
  };

  React.useEffect(() => {
    setTransition(()=>TransitionDown)
  }, [])
  
  const handleClose = () => {
    setState({ ...state, open: false });
  };

 

  return (
    <Box sx={{ width: 500 }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        ContentProps={{
          classes: {
            root: classes.root
          }
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={transition}
        transitionDuration={2000}
        key={vertical + horizontal}
        
        >

           <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
           To submit feedback please click on form link <a href="https://forms.office.com/r/gGcQv6aUrS" target='_blank'>Form</a>
  </Alert>
      </Snackbar>
    </Box>
  );
}