import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import './solubilityChart.css'
const SolubilityChart = ({ solventSelected, polymerSelected }) => {
    const chartRef = useRef();
    const legendRef = useRef();
    const solventLegendRef = useRef();
    const polymerLegendRef = useRef();

    useEffect(() => {
        // Extract Hildebrand values from the selected data
        const newSolvent = [...solventSelected];
        const newPolymer = [...polymerSelected];
        const solventHildebrand = newSolvent?.map(item => parseFloat(item.Hildebrand));
        const polymerHildebrand = newPolymer?.map(item => parseFloat(item.Hildebrand));

        const solventName = newSolvent?.map(item => item.Solvent);
        const polymerName = newPolymer?.map(item => item.Homopolymer);

        // Calculate the maximum Hildebrand value
        const maxHildebrand = Math.max(...solventHildebrand, ...polymerHildebrand);

        // Combine the solvent and polymer data
        const data = [
            ...solventHildebrand.map((value, index) => ({
                material: solventName[index],
                name: 'Solvent',
                Hildebrand: value,
                y: 6,
            })),
            ...polymerHildebrand.map((value, index) => ({
                material: polymerName[index],
                name: 'Polymer',
                Hildebrand: value,
                y: 6,
            })),
        ];

        // Adjusted Dimensions (increased width and height)
        const width = 800; // Increase width to 800
        const height = 500; // Increase height to 500
        const margin = { top: 20, right: 20, bottom: 40, left: 60 };

        // Extend the domain of the xScale to prevent truncation
        const xScale = d3.scaleLinear()
            .domain([0, maxHildebrand * 2]) // Extend the domain by 10%
            .range([margin.left, width - margin.right]);

        const yScale = d3.scaleLinear()
            .domain([0, 12])
            .range([height - margin.bottom, margin.top]);

        // Fixed radius for all bubbles
        const fixedRadius = 35;

        // Create SVG element
        const svg = d3.select(chartRef.current)
            .attr('width', width)
            .attr('height', height);

        // Check if axes already exist and remove them
        svg.selectAll('.x-axis').remove();
        svg.selectAll('.y-axis').remove();

        // Create circles
        const circles = svg.selectAll('circle')
            .data(data)
            .enter()
            .append('circle')
            .attr('cx', d => xScale(d.Hildebrand))
            .attr('cy', d => yScale(d.y))
            .attr('r', fixedRadius) // Use the fixed radius for all circles
            .attr('fill', d => d.name === 'Solvent' ? 'blue' : 'red')
            .style('fill-opacity', 0.8);

        // X-axis
        if (!svg.select('.x-axis').node()) {
            svg.append('g')
                .attr('class', 'x-axis')
                .attr('transform', `translate(0, ${height - margin.bottom})`)
                .call(d3.axisBottom(xScale));
        }

        // Event handler to show the name on hover
        circles.on('mouseover', function (event, d) {
            d3.select(this)
                .style('fill-opacity', 1); // Increase opacity on hover

            const tooltip = d3.select('#tooltip');
            tooltip
                .attr('class', 'tooltip-text')
                .html(`<div>${d.material} (${d.Hildebrand})</div>`)
                .style('left', `${event.pageX + 10}px`)
                .style('top', `${event.pageY - 20}px`)
                .style('opacity', 1);
        })
            .on('mouseout', function () {
                d3.select(this)
                    .style('fill-opacity', 0.8); // Reset opacity on mouseout

                const tooltip = d3.select('#tooltip');
                tooltip.style('opacity', 0);
            });

        // Y-axis
        svg.selectAll('.y-axis').remove(); // Remove the y-axis

        const solventLegend = d3.select(solventLegendRef.current)
            .attr('width', 200)
            .attr('height', 50);

        // Legend items for Solvent
        const solventLegendItems = [
            { color: 'blue', label: 'Solvent' },
        ];

        const marginText = 10; // Adjust this margin as needed

        solventLegend.selectAll('rect')
            .data(solventLegendItems)
            .enter()
            .append('rect')
            .attr('x', (d, i) => 10 + i * 70)
            .attr('y', 10)
            .attr('width', 20)
            .attr('height', 20)
            .attr('fill', d => d.color);

        solventLegend.selectAll('text')
            .data(solventLegendItems)
            .enter()
            .append('text')
            .attr('x', (d, i) => 40 + i * 70)
            .attr('y', 25) // Adjusted the y position to create margin
            .text(d => d.label);

        // Create the legend for Polymer
        const polymerLegend = d3.select(polymerLegendRef.current)
            .attr('width', 200)
            .attr('height', 50);

        // Legend items for Polymer
        const polymerLegendItems = [
            { color: 'red', label: 'Polymer' },
        ];

        polymerLegend.selectAll('rect')
            .data(polymerLegendItems)
            .enter()
            .append('rect')
            .attr('x', (d, i) => 10 + i * 70)
            .attr('y', 10)
            .attr('width', 20)
            .attr('height', 20)
            .attr('fill', d => d.color);

        polymerLegend.selectAll('text')
            .data(polymerLegendItems)
            .enter()
            .append('text')
            .attr('x', (d, i) => 40 + i * 70)
            .attr('y', 25) // Adjusted the y position to create margin
            .text(d => d.label);

    }, [solventSelected, polymerSelected]);

    useEffect(() => {
        console.log('solventSelected triggered')
    }, [solventSelected])
    

    console.log("polymerSelected",polymerSelected)

    return (
        <div className="chart-container">
            <div style={{display:"flex"}}>
                <svg ref={polymerLegendRef} className="legend"></svg>
                <svg ref={solventLegendRef} className="legend"></svg>
            </div>
            <div id="tooltip" className="tooltip"></div>
            <div>
                <svg ref={chartRef} className="chart"></svg>
            </div>
        </div>
    );
}

export default SolubilityChart
