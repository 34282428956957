// InputWithoutValidator.js
import React from 'react';
import {  Checkbox, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const InputWithoutValidator = ({ value, options, onChange, label, multiple, loading }) => {
  return (
    <Autocomplete
      multiple={multiple}
      value={value}
      options={options.sort((a, b) => a - b)}
      onChange={onChange}
      autoHighlight
      disabled={loading}
      renderTags={selected => {
        let renderTagsValue = selected
          .map(function(elem) {
            return elem;
          })
          .join(", ");
        return (
          <Typography noWrap={true} color="textPrimary">
            {renderTagsValue}
          </Typography>
        );
      }}
      getOptionLabel={(option) => option}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
        />
      )}
    />
  );
};

export default InputWithoutValidator;
