import React, { useState, useEffect } from "react";
import stylesheet from "../../stylesheet/searchresult.css";
import ContentResult from "./ContentResult";

export default function SearchResult(props) {
  const styles = {
    fontFamily: "sans-serif",
    textAlign: "center",
  };
  const intialStyle = {
    documentStyle: "column2",
    contentStyle: "column3",
    noResultStyle: "column4",
  };
  const [resultStyle, setResultStyle] = useState(intialStyle);

  useEffect(() => {
    console.log("resultCount", props.resultCount);
    console.log("searchResult", props.searchResult);
    console.log("searchResultHit", props.searchResult.searchResult.hits);
    console.log("searchResultHit", props.searchResult.searchResult.hits.hits);

    if (props.resultCount.docCount > 0) {
      // setResultStyle(
      //   prevState => ({...prevState,
      //     documentStyle:'column2',
      //     contentStyle:'column3',
      //     noResultStyle:'column4',
      //   }),
      // );
    } else if (props.resultCount.docCount > 0) {
      setResultStyle((prevState) => ({
        ...prevState,
        documentStyle: "only-column2",
        contentStyle: "display-non-column3",
        noResultStyle: "column4",
      }));
    }
    else if (props.resultCount.docCount == 0) {
      setResultStyle((prevState) => ({
        ...prevState,
        documentStyle: "display-non-column2",
        contentStyle: "display-non-column3",
        noResultStyle: "only-column4",
      }));
    }
  }, [props.resultCount]);
  const [activePage, setActivePage] = useState(5);

  const handlePageChange = (pageNumber) => {
    setActivePage((prevState) => ({ ...prevState, activePage: pageNumber }));
  };

  const onProps = () => {};

  return (
    <div>
      <div className="row">
        <div className="column1"> </div>

        {props.resultCount.docCount > 0 ? (
          <div id="divContentResult" className={resultStyle.contentStyle}>
            <ContentResult searchResult={props.searchResult}></ContentResult>
          </div>
        ) : (
          <></>
        )}

        <div className={resultStyle.noResultStyle}>
          Sorry no result found, Please try again with different search!
        </div>
      </div>
    </div>
  );
}
