import { Box, Button, Card, CardContent, Checkbox, CircularProgress, Grid, TextField, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Plot from 'react-plotly.js'
import SolubilityChart from './SolubilityChart';
import axios from '../common/AxiosConfig';
import SpherePlot from '../qualitative compatibility/bubblechart/SpherePlot';
import { useStyles } from '../qualitative compatibility/bubblechart/utils';
import TableOutput from '../common/TableOutput';
import { CSVLink } from "react-csv";
const HansenSolubility = () => {
    const classes = useStyles();
    const [solventOptions, setSolventOptions] = useState([])
    const [solventSelected, setSolventSelected] = useState([])
    console.log('solventSelected: ', solventSelected);
    const [polymerOption, setPolymerOption] = useState([])
    const [polymerSelected, setPolymerSelected] = useState([])
    const [hansenResponse, setHansenResponse] = useState({})
    const [hansenResponseAllData, setHansenResponseAllData] = useState([])
    const [filteredAllHansenData, setFilteredAllHansenData] = useState([])
    const [tableHeaders, setTableHeaders] = useState([])
    console.log('hansenResponseAllData: ', hansenResponseAllData);
    console.log('polymerSelected: ', polymerSelected);
    const [isLoading, setIsLoading] = useState(false)
    const [flag, setFlag] = useState(false)
    const [trace1, setTrace1] = useState([])
    const [trace2, setTrace2] = useState([])

    //get data from api


    useEffect(() => {
        let url = 'https://digipol.azurewebsites.net/hansen_polymers_data'
        setIsLoading(true)
        // setLoading(true)
  const token = localStorage.getItem('accessToken');

        axios({
            method: "GET",
            url: url,
              headers: {
        Authorization: `Bearer ${token}`,
    },
        }).then(res => {
            // setLoading(false)
            setPolymerOption(res?.data?.data)
        setIsLoading(false)

            // setFilteredPosts(res?.data?.data)
        })

    }, [])

    useEffect(() => {
 // Arrays provided


// Extract values from polymerSelected and solventSelected
const polymerValues = polymerSelected.map(item => item.Polymer);
const solventValues = solventSelected.map(item => item.Solvents);

// Combine polymer and solvent values
const combinedValues = new Set([...polymerValues, ...solventValues]);

// Filter the data array
const filteredData = hansenResponseAllData&&hansenResponseAllData?.data?.filter(item => combinedValues.has(item["Solvent/Polymer"]));
setFilteredAllHansenData(filteredData)
const headers = filteredData&& Object.keys(Object.assign({}, filteredData[0]));
setTableHeaders(headers)
console.log('result: ', headers);

console.log('filteredData',filteredData);

    }, [hansenResponseAllData,polymerSelected,solventSelected])


    useEffect(() => {
        let url = 'https://digipol.azurewebsites.net/hansen_solvents_data'
        // setLoading(true)
        setIsLoading(true)

  const token = localStorage.getItem('accessToken');

        axios({
            method: "GET",
            url: url,
                   headers: {
        Authorization: `Bearer ${token}`,
    },
        }).then(res => {
            setIsLoading(false)
            setSolventOptions(res?.data?.data)
            // setFilteredPosts(res?.data?.data)
        })

    }, [])
    const handlePolymer = (e, value) => {
        setFlag(false)

        let temp = [...polymerOption]
        let id_filter = value;
        var filtered = temp.filter(function (item) {
            return id_filter.indexOf(item.Polymer) !== -1;
        });
        // let arrayObj = filtered.map(item => {
        //     return {
        //       ...item,
        //       name: item.Homopolymer
        //     };
        //   });
        setPolymerSelected(filtered)
    }
    const handleSolvent = (e, value) => {
        setFlag(false)
        let temp = [...solventOptions]
        let id_filter = value;
        var filtered = temp?.filter(function (item) {
            return id_filter?.indexOf(item.Solvents) !== -1;
        });

        setSolventSelected(filtered)

    }


    const handleSubmit = async () => {
        console.log('calling');
        setFlag(false);
        // Initialize an empty array to store URL parameter strings
        let params = [`pol=${polymerSelected[0]?.Polymer}`];

// Iterate through each object in the array
solventSelected.forEach(obj => {
    let solvents = obj.Solvents;
    params.push(`sol=${encodeURIComponent(solvents)}`);
  });
  
  // Join all the parameter strings with '&' to form the complete query string
  let queryString = params.join('&');
  
        console.log('queryString: ', queryString);

// Construct the complete URL with parameters
        const sol = solventSelected[0]?.Solvents;
        const pol = polymerSelected[0]?.Polymer;
        const apiUrl = `https://digipol.shell.com/hansen_parameter?${queryString}`;
        const hansenAllDataUrl = `https://digipol.shell.com/hansen_all_data?${queryString}`;
    
        // Assuming your token is stored somewhere accessible
        const token = localStorage.getItem('accessToken');; // Replace with your actual token
    
        // Axios configuration with headers including Authorization token
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
    
        try {
            const response = await axios.get(apiUrl, config);
            setHansenResponse(response.data);
            setFlag(true);
            // Handle response data as needed
    
        } catch (error) {
            console.error('Error submitting data:', error);
            setFlag(true);
            // Handle error
        }
        try {
            const response = await axios.get(hansenAllDataUrl, config);
            setHansenResponseAllData(response.data);
            setFlag(true);
            // Handle response data as needed
    
        } catch (error) {
            console.error('Error submitting data:', error);
            setFlag(true);
            // Handle error
        }
    };
    
    const handleClear=()=>{
        setFlag(false)
        setSolventSelected([])
        setPolymerSelected([])
        setHansenResponse({})
        setHansenResponseAllData({})
    }
    if(isLoading){
        return <div> <Box sx={{ display: 'flex' }} style={{display:"flex",margin:"50px",justifyContent:"center"}}>
        <CircularProgress />
      </Box></div>
    }

    return (
        <div>
             <fieldset style={{ border: "1px solid #ddd", borderRadius: "8px", padding: "16px" }}>
    <legend style={{ fontWeight: 'bold', color: 'gray' }}>Filter</legend>
    <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={6} lg={6}>
            <Autocomplete
                autoComplete='off'
                sx={{ width: '100%' }}
                options={polymerOption.map(x => x.Polymer)}
                onChange={(e, newValue) => handlePolymer(e, newValue)}
                autoHighlight
                renderTags={(selected) => (
                    <Typography noWrap color="textPrimary">
                        {selected.join(", ")}
                    </Typography>
                )}
                getOptionLabel={(option) => option}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                        {option}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Polymer"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                        required
                        errorMessages={["This field is required"]}
                    />
                    
                )
                
            }
            />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
            <Autocomplete
                multiple
                autoComplete='off'
                sx={{ width: '100%' }}
                options={solventOptions.map(x => x.Solvents)}
                onChange={(e, newValue) => handleSolvent(e, newValue)}
                autoHighlight
                renderTags={(selected) => (
                    <Typography noWrap color="textPrimary">
                        {selected.join(", ")}
                    </Typography>
                )}
                getOptionLabel={(option) => option}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                        {option}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Solvent"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                        required
                        errorMessages={["This field is required"]}
                    />
                )}
            />
        </Grid>
    </Grid>
</fieldset>
<div style={{ display:"flex",justifyContent:"center",width:"100%",marginTop:"10px" }}>
           
           <Button id="btnPhCalcPredict" variant="contained" color="primary" onClick={handleSubmit} > Submit
           </Button>
           {/* <Button id="btnPhCalcReset" style={{ marginLeft: '20px' }} onClick={handleClear}  variant="contained" color="primary"> Clear
           </Button> */}
           <br></br>

         </div>

            {flag &&
            <Grid container spacing={2} style={{marginTop:"10px"}}>
  <Grid item xs={12} md={12}>


{/* <SolubilityChart key={`${solventSelected}-${polymerSelected}`}  solventSelected={solventSelected} polymerSelected={polymerSelected}/> */}
<SpherePlot key={`${solventSelected}-${polymerSelected}`} hansenResponse={hansenResponse}  solventSelected={solventSelected} polymerSelected={polymerSelected}/>
 
</Grid>
<br></br>
{filteredAllHansenData &&
<Grid item xs={12}>

<div style={{display:"flex",justifyContent:"end"}}>

<div id='divCSVDownload' style={{ float: 'right',marginTop:"20px" }}>
   <CSVLink filename={"Hansen" + '.csv'} data={filteredAllHansenData} headers={tableHeaders}>
     <Button variant="contained" color="primary">
       Export Excel
     </Button>
   </CSVLink>
 </div>
</div>
</Grid>
}
 <br></br>
 <br></br>
<Grid item xs={12} md={12}>
{filteredAllHansenData? <Card className={classes.root,"tableContainer"} variant="outlined">
              <CardContent><TableOutput TableData={filteredAllHansenData} />
              </CardContent>
            </Card>:<Card className={classes.root,"tableContainer"} variant="outlined">
              <CardContent><Typography>No Data Available</Typography></CardContent>
            </Card>
            }
</Grid>
</Grid>
}
        </div>
    )
}

export default HansenSolubility