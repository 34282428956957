import React from 'react';
import './App.css';
import './stylesheet/crackingstylesheet.css';
import ShellHeaderDrawer from './components/shellheader/ShellHeaderDrawer';
import Store from "./context/store";

function App() {
  const [isAuthenticated, setIsAuthenticated] = React.useState('NotValidatedYet');

  // React.useEffect(() => {

  //   var hours = 1;
  //   var now = new Date().getTime();
  //   var setupTime = window.localStorage.getItem('setupTime');
  //   if (setupTime !== null) {
  //      if (now - setupTime > hours * 60 * 60 * 1000) {
  //        window.localStorage.clear()
  //      }
  //    }
  //   if (localStorage.getItem('autheticatedUser') && localStorage.getItem('autheticatedUser') == 'allowed') {
  //     console.log('ALLLOWED')

  //     setIsAuthenticated('Allow');
  //   } else {
  //     console.log('NOTALLLOWED')
  //     setIsAuthenticated('Allow');
  //   }
  
  // }, []);

  return (
    <>

  {/* {isAuthenticated === 'NotValidatedYet' && <div className="App">

      </div>} */}

      {/* {isAuthenticated === 'Allow' && <div className="App"> */}

        <Store>

        <ShellHeaderDrawer></ShellHeaderDrawer>
        </Store>

      {/* </div>}  */}
       {/* {isAuthenticated === 'Failed' &&
        <div className='authErrorDiv'>
          <h1 style={{ textAlign: 'center' }}> Not Authorized</h1>
          <p>Sorry, you are not authozired to view the requested site. Please contact site administrator for access (Ghosh, Sukanta PTIN-PTX/G/M).</p>
        </div>} */}
    </>
  );
}

export default App;
