import React,{ useState, useEffect,useMemo } from 'react';
import MaterialTable from 'material-table';
import stylesheet from '../../stylesheet/searchresult.css';
import ProgessBarPercentage from "./ProgessBarPercentage";



export default function ContentResult(props) { 
 const [dataSegments, setDataSegments] = useState({})
 const [suggestedQuery, setSuggestedQuery]= useState();
 const fileBaseURL='https://matinsights.azurewebsites.net/get_file?path=UI/src/filereferences/literature_search'; // removed slask at the end

 const [listOfImages, setListOfImages] = useState(
  importAll(
    require.context("../../images/icons", false, /\.(png|jpe?g|svg)$/)
  )
);

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });

  return images;
}

const listOfIcons = [
  {
    keyName: "docx",
    imageFile: "icdocx.svg",
  },
  {
    keyName: "doc",
    imageFile: "icdocx.svg",
  },
  {
    keyName: "ppt",
    imageFile: "icpptx.svg",
  },
  {
    keyName: "pdf",
    imageFile: "icpdf.svg",
  },
  {
    keyName: "pptx",
    imageFile: "icpptx.svg",
  },
  {
    keyName: "xlsx",
    imageFile: "icxlsx.svg",
  },
  {
    keyName: "xls",
    imageFile: "icxlsx.svg",
  },
  {
    keyName: "zip",
    imageFile: "iczip.svg",
  },
  {
    keyName: "jpg",
    imageFile: "photo.svg",
  },
  {
    keyName: "jpeg",
    imageFile: "photo.svg",
  },
  {
    keyName: "defaultFile",
    imageFile: "genericfile.svg",
  },
];
  useEffect(() => {
    let docs=props.searchResult;
console.log('Changed Non-Metallic (Polymer) Reports');
    let dataArray=[];
    let fileRefDoc='';
    let resFileFormat;
    let fileIcon;

    if(docs.searchResult.hits){
      setSuggestedQuery(docs.searchResult.suggested_query)
    {props.searchResult.searchResult.hits && docs.searchResult.hits.hits.map(item => (
      // fileRefDoc = require('../../filereferences/literature_search' +item._source.path.virtual),
      fileRefDoc = fileBaseURL+item._source.path.virtual,
      // fileRefDoc=fileRefDoc.replace('Non-Metallic (Polymer) Reports','Non Metallic Reports'),
      // console.log('filepath', fileRefDoc),
       resFileFormat = listOfIcons.find(
        (x) => x.keyName == item._source.extension.toLowerCase()
      ),
  
       fileIcon =
        listOfImages[
        resFileFormat && resFileFormat.imageFile
          ? resFileFormat.imageFile
          : "defaultFile"
        ],
     dataArray.push({para: <div>
      <div style={{'display':'flex'}}> <h2 style={{marginBottom:'5px', marginTop:'10px', 'width':'90%'}}><b> 
        <img
            className="pdfIconCss"
            src={fileIcon}
            alt="File extension icon"
            style={{ marginBottom: "3px" }}
          ></img><a href={fileRefDoc} target="_blank">{item._source.filename_without_extension}</a></b></h2>
         
      <ProgessBarPercentage score={item._score}></ProgessBarPercentage>
      </div>
      <div
          style={{ marginTop: "2px", marginBottom: "5px"}}
        >
          <div>
            {" "}
            <span>Path: </span>
            <span style={{ color: "#006621" }}>{item._source.path.virtual}</span>
          </div>
        </div>
       {item.highlight.content.map(content=>(
     <div  dangerouslySetInnerHTML={{ __html: content }} ></div> ))  }  
      {/* <span style={{float: 'right','font-weight':'bold'}}> */}
        {/* &nbsp;&nbsp;Path:&nbsp; */}
      {/* <a>{item._source.path.virtual}</a>  */}
      {/* &nbsp;&nbsp;Score:&nbsp;{ (Math.round(item._score * 10)).toFixed(0)}%
      </span> */}
      <br></br>

  </div>})
    ))} }

  setDataSegments(
      prevState => ({...prevState,
        dataSegments:dataArray
      }),
    );    
  }, [props.searchResult])

  const [state, setState] = React.useState({
    columns: [
        { title: 'para', field: 'para' },    
    ],
   
  });

 
  return (
    <>
    {suggestedQuery && <><div style={{
   ' margin-left': '25px',
    'text-align': 'left'}} dangerouslySetInnerHTML={{ __html:"Showing results for "+ suggestedQuery }}></div><br></br></>}
    <MaterialTable
      columns={state.columns}
      data={dataSegments.dataSegments}   
    />
     <div style={{'text-align': 'left',    'margin-left': '25px',
    'color': 'grey'}}>Note: Search scores are relative to the top search result.
    </div>
    </>
  );
}