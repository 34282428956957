import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import PropTypes from 'prop-types';
const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    background:'white!important'
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));



export default function SearchBar(props) {
  const classes = useStyles();
  const handleFormSubmit=(event)=>{
    event.preventDefault();
    props.onSearchClick()
  
  }

  const handleSearchClick = () => {
    props.onSearchClick();
  };

  const handleSearchKeyPress = (event) => {
    // Check if Enter key is pressed (key code 13)
    if (event.key === 'Enter') {
      handleSearchClick();
    }
  };
  return (
    <div id="divSearchBar">
    <Paper component="form" onSubmit={handleFormSubmit} className={classes.root} >
      <IconButton className={classes.iconButton} aria-label="menu">
      </IconButton>
      <InputBase
        className={classes.input}
        onChange={props.changeSearchText}
        placeholder="Enter Your Search Text"
        inputProps={{ 'aria-label': 'search google maps' }}
        style={{'background':'white'}}
      />
      <div id='btnSearch' 
         tabIndex='0' 
         onClick={handleSearchClick} 
         onKeyDown={handleSearchKeyPress} 
         style={{ cursor: 'pointer' }}>
      
      <IconButton  className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton></div>
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton color="primary" className={classes.iconButton}>
        <FilterListIcon />
      </IconButton>
    </Paper>
    </div>
  );
}

SearchBar.propTypes = {
  onSearchClick: PropTypes.func.isRequired,
  changeSearchText: PropTypes.func.isRequired,
  // Other props you expect, if any
};