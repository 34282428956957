import { Box, Grid, Paper } from '@material-ui/core'
import React from 'react'
import DataVolumeTable from './DataVolumeTable'

const DataVolume = () => {
  return (
    <div>
     <Box>
     <Grid container spacing={2} >
     <Grid item xs={12} lg={12} >
    
  <div style={{border:"1px solid silver",width:"100%",height:"100%",display:"flex",flexDirection:"column"}}>
  <iframe style={{display:"block",width:"100%"}} className='hello' title="Data Volume"  height={600} src="https://app.powerbi.com/reportEmbed?reportId=6511f393-ecb7-4d74-b564-76ee4cf5719c&appId=d07be136-146a-48d2-a53f-38c468e78052&autoAuth=true&ctid=db1e96a8-a3da-442a-930b-235cac24cd5c" frameborder="0" allowFullScreen={true}></iframe>
    </div>
    </Grid>
     {/* <Grid item xs={12} lg={6} >
    
  <div style={{border:"1px solid silver",width:"100%",height:"100%",display:"flex",flexDirection:"column"}}>
     hello
    </div>
    </Grid>
     <Grid item xs={12} lg={6} >
    
  <div style={{border:"1px solid silver",width:"100%",height:"100%",display:"flex",flexDirection:"column"}}>
     hello
    </div>
    </Grid>
     <Grid item xs={12} lg={6} >
    
  <div style={{border:"1px solid silver",width:"100%",height:"100%",display:"flex",flexDirection:"column"}}>
     hello
    </div>
    </Grid> */}
    </Grid>

    </Box>
    {/* <Box style={{marginTop:"2%"}}>
    <Grid container spacing={2}>
<Grid xs={12}>

       <DataVolumeTable/>
</Grid>
<Grid xs={12}>

       <DataVolumeTable/>
</Grid>
<Grid xs={12}>

       <DataVolumeTable/>
</Grid>
    </Grid>
    </Box> */}

    {/* This page is in progress... */}
    </div>
  )
}

export default DataVolume