import { Box, Container, Grid, Link, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import homebanner from '../../images/banner.png';
import ImgMediaCard from '../cards/';
import Snackbar from '../common/SnackbarComp';
import SnackbarComp from '../common/SnackbarComp';
// import * as AzureStorageFileShare from "@azure/storage-file-share";

// const { ShareServiceClient } = require("@azure/storage-file-share");
// const { DefaultAzureCredential } = require("@azure/identity");

// const { BlobServiceClient,  StorageSharedKeyCredential } = require("@azure/storage-file-share");


export default function HomePage(props) {

    // const account = "";
    // const accountKey = "";
    // const sas = '';
    // const shareName = '';
    // const fileName = 'TestFile.pdf';
    // const defaultAzureCredential = new DefaultAzureCredential();
    // const sharedKeyCredential = new StorageSharedKeyCredential(account, accountKey);

    // const credential = new StorageSharedKeyCredential(account, accountKey);
    // const serviceClient = new ShareServiceClient(
    //   `https://${account}.file.core.windows.net`,
    //   credential
    // );
    // const serviceClient1 = new ShareServiceClient(`https://${account}.file.core.windows.net${sas}`);
    // const serviceClient = new ShareServiceClient('');
    // const blobServiceClient = new BlobServiceClient('',
    // sharedKeyCredential);
    // const blobServiceClient = new BlobServiceClient('',
    // defaultAzureCredential);

    //const shareName = "";
    // const directoryName = "test_reference";
    useEffect(() => {
        //abcde();
    }, [])
    //     async function abcde() {
    //     let i = 1;
    //   for await (const container of blobServiceClient.listContainers()) {
    //     console.log(`Container ${i++}: ${container.name}`);
    //   }
    // }
    //     async function abcd() {

    //         // const fileClient = serviceClient
    //         //   .getShareClient(shareName)
    //         //   .rootDirectoryClient.getFileClient(fileName);
    //         const containerClient = blobServiceClient.getContainerClient('containerName');
    //         const blobClient = containerClient.getBlobClient('blobName');

    //         const downloadBlockBlobResponse = await blobClient.download();
    //         const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody);
    //         console.log(
    //           "Downloaded blob content",
    //           downloaded
    //         );
    //         // Get file content from position 0 to the end
    //         // In browsers, get downloaded data by accessing downloadFileResponse.blobBody
    //        // const downloadFileResponse = await fileClient.download(0);
    //         // console.log(
    //         //     `Downloaded file content: ${await blobToString(await downloadFileResponse.blobBody)}`
    //         //   );
    //         //   blobToStringaaa(await downloadFileResponse);
    //         // console.log(
    //         //   `Downloaded file content: ${await blobToString(await downloadFileResponse.blobBody)}`
    //         // );
    //       }
    // async function blobToStringaaa(blob) {
    //   console.log('blobToStringaaa');

    //     console.log('blobToStringaaa', blob);
    //   var url = window.URL.createObjectURL(blob);
    //       var a = document.createElement('a');
    //       a.href = url;
    //       document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    //       a.click();    
    //       a.remove();  //afterwards we remove the element again    
    //       console.log('remove');

    // }
    //   var 
    // doc = window.document,
    // userFile = doc.getElementById('userFile'),
    // preview = doc.getElementById('preview'),
    // domParser = new DOMParser();

    //   const fileutil = {
    //     init: function() {
    //       userFile.addEventListener('change', fileutil.onchange, false);
    //     }
    //   }

    // [Browser only] A helper method used to convert a browser Blob into string.
    async function blobToString(blob) {
        const fileReader = new FileReader();
        // var x= new Promise((resolve, reject) => {
        //   fileReader.onloadend = (ev) => {
        //     resolve(ev.target.result);
        //   };
        //   fileReader.onerror = reject;
        //   fileReader.readAsText(blob);
        //   var self = this;
        //   var iframe = doc.createElement('iframe');
        //   iframe.width = '100%';
        //   iframe.height = 300;    
        //   fileReader.onload = function(e) {
        //     if (e.target.readyState === 2) { // 2 means DONE
        //       document.body.appendChild(iframe);
        //      // fileutil._ready(e.target.result);
        //     }
        //   }
        // }); 
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = 'xzy.pdf';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again    
        console.log('remove');
        window.URL.revokeObjectURL(url);
        //   var a = document.createElement('a');
        //   a.href = url;
        //   a.target ='_blank';
        //   a.download = "TestFile5.pdf";
        //   document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        //   a.click();    
        //   a.remove();  //afterwards we remove the element again    
        //   console.log('remove');
        return 'done';
        // const fileReader = new FileReader();
        // return new Promise((resolve, reject) => {
        //   fileReader.onloadend = (ev) => {
        //     resolve(ev.target.result);
        //   };
        //   fileReader.onerror = reject;
        //   fileReader.readAsText(blob);
        // });
    }

    // async function abc() {
    //     const directoryClient = serviceClient.getShareClient(shareName).getDirectoryClient(directoryName);

    //     let dirIter = directoryClient.listFilesAndDirectories();
    //     let i = 1;
    //     for await (const item of dirIter) {
    //       if (item.kind === "directory") {
    //         console.log(`${i} - directory\t: ${item.name}`);
    //       } else {
    //         console.log(`${i} - file\t: ${item.name}`);
    //       }
    //       i++;
    //     }
    //   }
    const linkData = [

        {
            title: 'Quantitative Data',
            desc: 'Shell generated experimental (quantitative) data to provide information on chemical and mechanical compatibility of exposed polymers.'
        },
        {
            title: 'Qualitative Compatibility Data',
            desc: 'Qualitative compatibility information of exposed polymers in the form of scatter and contour plots. It uses both Shell and externally available qualitative data. '
        },
        {
            title: 'Compatibility Data for CCS',
            desc: 'Qualitative as well as quantitative information about how polymers are impacted by CO2 and uses information from both internal and external technical reports.'
        },
        {
            title: 'Search Reports',
            desc: ' Search information from Shell reports using key words. Failure analysis reports as well as other Shell reports on polymers and composites, their suitability for CCS can be looked up using the tool.'
        },

    ]

    const handleFunc = (index) => {
        props.homePageMainlinkFuncs[index](true);
        props.homePageSublinkFuncs[index] && props.homePageSublinkFuncs[index](true);
        props.homePagelinkFuncs[index]();
    }

    return (
        <>

            <div style={{ textAlign: 'left', fontSize: '13px' }}>
                <img style={{ 'width': '100%', objectFit: 'cover' }} src={homebanner} alt="picten"></img>
                <h4>WELCOME TO DIGITAL POLYMER</h4>
                <p style={{ fontSize: '14px' }}>
                    The Digital Polymer (DP) tool is developed to assist M&C Engineers in quick retrieval of polymer compatibility data thereby enabling easier and robust material selection and qualification. Unstructured information on polymers and composites was collected and processed from technical reports (internal as well as external) to build a centralized digital database. In addition, a search application has been developed based on text analytics to retrieve source files reporting failure analysis and other information on non-metallic materials. The web interface with underlying database has visuals in 4 consecutive sections, as seen in left grey pane of the window.
                </p>
                <div id='linkCards' style={{ display: 'flex' }} >
                    {linkData.map((value, index) => {
                        return <ImgMediaCard key={index} index={index}
                            style={{ 'width': '20%', 'height': '100px', marginRight: '2%' }} title={value.title} desc={value.desc}></ImgMediaCard>
                    })}
                </div>
                {/* <div style={{position:"fixed",bottom:0,right:0,height:"13%",padding:"1%",width:"18%",border:"1px solid gray",borderRadius:"5px",backgroundColor:"lightyellow",opacity:"0.9"}}>
                    <h3>To submit feedback please click on form link <a href="https://forms.office.com/r/gGcQv6aUrS" target='_blank'>Form</a></h3>
                    
                </div> */}
                <div>
                    <SnackbarComp/>
                </div>

                {/* <p>
    This data science powered web application has been created predominantly for the use of materials and corrosion
    engineers covering three key areas:
</p><ul>
    <li>
        Data Centralization
    </li>
    <li>
        Predictive Modelling
    </li>
    <li>
        Knowledge Management
    </li>
</ul>
<p></p>
<p>
    This digital web platform captures data of both Corrosion Resistant Alloys (CRA) and Carbon Steel. It should be
    noted that as of now, this database predominantly covers the performance of CRAs and Carbon Steel materials in EP
    (Upstream) services.
    The “Data Centralization” aspect of this platform covers key areas such as, collect data from multiple sources,
    store data in central database, enable data download, enable data upload with admin approval.
    From the “Knowledge Management” point of view, this platform will enable ease access to the materials &amp; corrosion
    engineers in the key areas such as, Boundary limits from DEP, Material recommendation based on DEP limits, CRA
    guideline (Shell’s legacy tool), Searchable repository etc.
</p>
<p>
    Predictive modelling, especially use of data science in predicting the cracking performance of CRAs and carbon &amp; low
    alloy steel is a very new feature which have not been deployed or widely used by the materials &amp; corrosion community
    earlier. Predictive modelling aspect of this web- based platform features: probability of cracking in CRAs (e.g.,
    13Cr, Super 13Cr, 22Cr, 25Cr), sour fracture toughness of low alloy carbon steels (C125, C110, T95, C90), general
    corrosion of certain CRAs in acids (22Cr in HCl, formic/acetic acid etc.). Data driven model has also been created
    for prediction of CO2/H2S corrosion for carbon steel and the model prediction has been compared to the laboratory
    test data as well as with the mechanistic corrosion prediction model as used in Hydrocor.
</p>
<p>
    This digital web platform captures all the data that was documented in the “CRA Guide” and was last updated in 2009.
    The old CRA Guide was basically a spreadsheet compilation of performances of various commonly used CRAs in the
    upstream environment. The CRA guide also contained background information on metallurgy of CRA’s, Shell and Industry
    specifications (mainly NACE and ISO), selection guides for various applications (e.g., topside, downhole, shell &amp;
    tube heat exchangers, seawater etc.), laboratory testing protocols, reported CRA failures, manufactures &amp; sources of
    technical information on uses of CRAs. All those information have been captured in the current web platform, but in
    a new format which is more easy and intuitive to use and also easily searchable. It should be noted that the “CRA
    guide” has not been updated for last 10 years. An effort is currently undergoing to collect all the ‘recent’ data
    and incorporate them in this web platform.
</p>
<p>
    The overall objective of this platform is to provide a “Data science powered web application for materials &amp;
    corrosion engineers” so that they can take an informative decision while selecting a material based on the available
    data and predictive modelling.
</p> */}
            </div>
            <Box
                marginTop={8}
                bgcolor="text.secondary"
                color="white"
                borderRadius={4}
                px={{ xs: 3, sm: 8 }}
                py={{ xs: 5, sm: 8 }}
                style={{ boxShadow: "1px 1px 1px 3px silver" }}

            >
                <Container maxWidth="lg" >
                    <Grid container spacing={2}>
                        <Grid item sx={12} sm={10}>
                            <Box >
                                <Typography variant="h5" component="div">
                                    Disclaimer
                                </Typography>
                            </Box>
                            <Box py={{ xs: 5, sm: 5 }} >
                                <Typography style={{ fontSize: "18px" }}>The web tool does not replace the selection process for non-metallic materials as per Shell DEPs. </Typography>
                            </Box>
                        </Grid>
                        <Grid item sx={12} sm={2}>
                            <Box >
                                <Typography variant="h5" component="div">
                                    Release Notes
                                </Typography>
                            </Box>
                            <Box >
                                <Link color="inherit">
                                    Current release: v6.0
                                </Link>
                            </Box>
                            <Box>
                                <Link color="inherit">
                                    First release: v6.0
                                </Link>
                            </Box>
                            <Box>
                                <Link color="inherit">
                                    The Webpage is developed at IDA CC - Asset Management Data Science.
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )

}