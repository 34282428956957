import { Box, Grid, Paper } from '@material-ui/core'
import React from 'react'

const ProgressReport = () => {
  return (
    <div>
     <Box>
     <Grid container spacing={2} style={{height:"90vh"}} >
     <Grid item xs={12} lg={12} >
    
  <div style={{border:"1px solid silver",width:"100%",height:"100%",display:"flex",flexDirection:"column"}}>
  <iframe style={{display:"block",width:"100%"}} className='hello' title="Data Volume"  height={600} src="https://eu001-sp.shell.com/sites/AAFAA6278/_layouts/15/Doc.aspx?sourcedoc={9b9c7bae-7846-4a9d-8854-b0011a1783eb}&amp;action=embedview" frameborder="0" allowFullScreen={true}></iframe>
    </div>
    </Grid>
    </Grid>

    </Box>
  
    </div>
  )
}

export default ProgressReport