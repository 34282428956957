import React from "react";
import { Checkbox, FormControl, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { TextValidator } from "react-material-ui-form-validator";

const DropdownInput = ({
  classes,
  selected,
  polymerOption,
  handleChange,
  loading,
  validating,
  label,
  multiple,
  withCheckboxes,
}) => {
  return (
    <FormControl className={classes.formControl}>
      <Autocomplete
        multiple={multiple}
        id={label}
        value={selected}
        sx={{ width: 300 }}
        options={polymerOption?.sort()}
        onChange={handleChange}
        autoHighlight
        disabled={!polymerOption?.join("").length > 0 || loading}
        renderTags={(selected) => {
          let renderTagsValue = selected.map((elem) => elem).join(", ");
          return (
            <Typography noWrap={true} color="textPrimary">
              {renderTagsValue}
            </Typography>
          );
        }}
        getOptionLabel={(option) => option}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            {withCheckboxes && (
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
            )}
            {option}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextValidator
            {...params}
            label={label}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
            }}
            required
            value={selected}
            validators={polymerOption?.join("").length > 0 && validating}
            errorMessages={["This field is required"]}
          />
        )}
      />
    </FormControl>
  );
};

export default DropdownInput;
